import { CoreButton } from '@packages';
import { message, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import InlineGenericInput from '@components/common/InlineGenericInput';
import GenericCheckbox from '@components/common/GenericCheckbox';
import { IUpdateTierPayload } from '@models/billing.model';
import { unwrapResult } from '@reduxjs/toolkit';
import { SubscribeAPI } from '@service/subscription.service';
import { RegexValidators } from '@config/regex-validator-constant';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { useAppDispatch } from "@store/hooks";
import { getTiers } from "@store/slices/subscribeSlice";

interface IUpdateTierProps {
    isOpenModal: boolean;
    onCancelModal: () => void;
    tier: IUpdateTierPayload | undefined;
}

export default function UpdateTier({ isOpenModal, onCancelModal, tier }: IUpdateTierProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IUpdateTierPayload>({
        mode: 'onChange',
        defaultValues: {},
    });

    useEffect(() => {
        if (tier) {
            reset(tier);
        }
    }, [tier, reset]);

    const onSubmit = async (data: IUpdateTierPayload) => {
        const payload = {
            ...data,
            MonthlyPrice: Number(data.MonthlyPrice),
            NumberOfDevices: Number(data.NumberOfDevices),
            NumberOfEntities: Number(data.NumberOfEntities),
            NumberOfInvoices: Number(data.NumberOfInvoices),
            NumberOfOrganizations: Number(data.NumberOfOrganizations),
            NumberOfUsers: Number(data.NumberOfUsers),
            YearlyPrice: Number(data.YearlyPrice),
        };

        await SubscribeAPI.updateTire(payload)
            .then(unwrapResult)
            .then((result) => {
                message.success('Tier Successfully Updated');
                onCancelButton();
            })
            .catch((err) => {
                message.error('Tire Update Failed');
            })
            .finally(() => {
                dispatch(getTiers())
            });
    };

    const onCancelButton = () => {
        onCancelModal();
        reset();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal
                width={800}
                open={isOpenModal}
                closable={false}
                title={'Update Tier'}
                footer={[
                    <CoreButton key={1} text={'Cancel'} type={'basic'} onClick={onCancelButton} />,
                    <CoreButton
                        key={2}
                        text={'Update'}
                        type={'primary'}
                        onClick={() => {
                            handleSubmit(onSubmit)();
                        }}
                        className='submit-modal-button'
                    />,
                ]}
            >
                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Currency'
                    level='Currency'
                    marginBottom={15}
                    errorMessageSize='small'
                    rules={{
                        required: true,
                    }}
                />

                <GenericCheckbox
                    control={control}
                    levelCol={24}
                    inputCol={24}
                    dataKey='IsPublic'
                    level='Is Public'
                />

                <GenericCheckbox
                    control={control}
                    levelCol={24}
                    inputCol={24}
                    dataKey='IsActive'
                    level='Is Active'
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='MonthlyPrice'
                    level='Monthly Price'
                    marginBottom={15}
                    errorMessageSize='small'
                    type={'number'}
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Name'
                    level='Name'
                    marginBottom={15}
                    errorMessageSize='small'
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Description'
                    level='Description'
                    marginBottom={15}
                    errorMessageSize='small'
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='NumberOfDevices'
                    level='Number Of Devices'
                    marginBottom={15}
                    errorMessageSize='small'
                    type={'number'}
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='NumberOfEntities'
                    level='Number Of Entities'
                    marginBottom={15}
                    errorMessageSize='small'
                    type={'number'}
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='NumberOfInvoices'
                    level='Number Of Invoices'
                    marginBottom={15}
                    errorMessageSize='small'
                    type={'number'}
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='NumberOfOrganizations'
                    level='Number Of Organizations'
                    marginBottom={15}
                    errorMessageSize='small'
                    type={'number'}
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='NumberOfUsers'
                    level='Number Of Users'
                    marginBottom={15}
                    errorMessageSize='small'
                    type={'number'}
                    rules={{
                        required: true,
                    }}
                />

                <GenericCheckbox
                    control={control}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Sdkvalidation'
                    level='Sdk Validation'
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='YearlyPrice'
                    level='Yearly Price'
                    marginBottom={15}
                    errorMessageSize='small'
                    rules={{
                        required: true,
                    }}
                />

                <InlineGenericInput
                    control={control}
                    errors={errors}
                    levelCol={24}
                    inputCol={24}
                    dataKey='Email'
                    level={t('EMAIL_ADDRESS')}
                    marginBottom={15}
                    rules={{
                        pattern: {
                            value: RegexValidators.EMAIL,
                            message: t('INVALID_ENTRY_EG'),
                        },
                    }}
                />
            </Modal>
        </form>
    );
}
