import { checkPostalCode } from '@utils/checkInputType';
import { Col, Input, Row } from 'antd';
import { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import GenericErrorMessage, { ErrorMessageSize } from './GenericErrorMessage';
import GenericTooltip from './GenericTooltip';
import { useTranslation } from 'react-i18next';
import { CustomRules } from '@models/rules.model';
import { isFiledFoundError, isRequiredValue } from '@utils/errorMessageOption';

interface IPostalCodeGenericBaseParams {
    control: Control<any, any>;
    errors: FieldErrors<any>;
    level: string;
    dataKey: string;
    disabled?: boolean;
    rules?: CustomRules;
    levelCol?: number;
    inputCol?: number;
    showLevel?: boolean;
    marginBottom?: number;
    placeHolder?: string;
    hideError?: boolean;
    children?: React.ReactNode;
    gutter?: number;
    tooltip?: string;
    errorMessageSize?: ErrorMessageSize;
    type?: string;
}

const GenericPostalCode: FC<IPostalCodeGenericBaseParams> = ({
    control,
    errors,
    level,
    dataKey,
    rules,
    disabled,
    levelCol = 8,
    inputCol = 16,
    showLevel = true,
    marginBottom = 30,
    placeHolder,
    hideError = false,
    children,
    gutter = 130,
    tooltip,
    errorMessageSize,
    ...others
}) => {
    const { t } = useTranslation();

    return (
        <Row gutter={gutter} align='middle' style={{ marginBottom: marginBottom }}>
            {showLevel && (
                <Col span={levelCol}>
                    <label className='general-label'>
                        {t(level)}
                        {level && rules?.required && <span className='required-field'>*</span>}
                        {tooltip && <GenericTooltip text={tooltip} />}
                    </label>
                </Col>
            )}
            <Col span={inputCol}>
                <Controller
                    name={dataKey as any}
                    render={({ field }) => (
                        <Input
                            status={isFiledFoundError(errors, dataKey) ? 'error' : undefined}
                            {...field}
                            onKeyPress={checkPostalCode}
                            type='number'
                            placeholder={placeHolder ? placeHolder : t(level)}
                            max={rules?.max as number}
                            min={rules?.min as number}
                            disabled={disabled ?? false}
                            className='general-input remove-dropdown'
                        />
                    )}
                    control={control}
                    rules={{ ...rules, required: isRequiredValue(rules) }}
                />
                {!hideError && (
                    <GenericErrorMessage
                        errors={errors}
                        dataKey={dataKey}
                        rules={rules}
                        level={level}
                        errorMessageSize={errorMessageSize}
                    />
                )}
                {children}
            </Col>
        </Row>
    );
};

export default GenericPostalCode;
