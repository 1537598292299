import CardView from './CardView';
import React from 'react';
import './CardList.scss';
import { Spin } from 'antd';
import { useAppSelector } from '@store/hooks';
import { selectPaymentMethods, selectPaymentMethodsIsLoading } from '@store/slices/paymentSlice';

export interface ICardListProps {
    selectedCardId?: string;
    setSelectedId?: (id: string) => void;
}

export default function CardList({ selectedCardId, setSelectedId }: ICardListProps) {
    const paymentMethods = useAppSelector(selectPaymentMethods);
    const isLoading = useAppSelector(selectPaymentMethodsIsLoading);
    return (
        <Spin tip='Loading' size='small' spinning={isLoading}>
            <div className='card-list'>
                {paymentMethods?.map((card) => (
                    <CardView
                        key={card.ID}
                        type={card.Brand}
                        number={card.Last4}
                        expiry={card.Expire}
                        selectedCardId={selectedCardId}
                        cardId={card.ID}
                        onClick={() => setSelectedId && setSelectedId(card.ID)}
                    />
                ))}
            </div>
        </Spin>
    );
}
