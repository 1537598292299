import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table, Tag, Tooltip } from 'antd';
import { CoreButton, CoreSearchInput, CoreStatus } from '@packages';
import { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from 'react-router-dom';
import InviteClient from '@components/invite-client/InviteClient';
import FilterDropDown from '@components/filter-drop-down/FilterDropDown';
import { ConfirmationModal } from '@components';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { useAppDispatch } from '@store/hooks';
import { disableEntity, enableEntity, getEntities } from '@store/slices/organizationSlice';
import { IEntity } from '@models/organization.model';
import { UtcToLocalDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { EntityFilterInputs } from '../../index';

interface IOrganizationEntityTableData {
    key: React.Key;
    EntityId: string;
    LegalRegistrationName: string;
    LegalRegistrationNameEn: string;
    CompanyRegistrationNumber: string;
    CreatedAt: string;
    IsActive: boolean;
    ActionButtons: boolean;
    EditButtons: string;
    VatRegistrationNumber: string;
}

const displayValues: any = {
    EntityId: 'Entity ID',
    legalRegistrationName: 'Legal Registration Name',
    vatRegistrationNumber: 'VAT Registration Number',
    organizationLegacyId: 'Organization Legacy ID',
    dateRange: 'Date Range',
    activeStatus: 'Active Status',
    organizationRegistrationScheme: 'Organization Registration Scheme',
};

const OrganizationEntity = () => {
    const { t } = useTranslation();
    const { organization } = useSelector((state: RootState) => state.organization);
    const [entityList, setEntityList] = useState<IOrganizationEntityTableData[]>([]);
    const { isLoading, entities } = useSelector((state: RootState) => state.organization);
    const { organizationId = '' } = useParams();
    const dispatch = useAppDispatch();
    const [isActiveModalOpen, setActiveModalOpen] = useState(false);
    const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [entityId, setEntityId] = useState<string>('');

    useEffect(() => {
        if (organizationId) {
            dispatch(getEntities(organizationId));
        }
    }, []);

    const formatEntityListData = () => {
        const data: IOrganizationEntityTableData[] = [];
        entities.forEach((entity: IEntity) => {
            data.push({
                key: entity.EntityId || '',
                EntityId: entity.EntityId,
                LegalRegistrationName: entity.LegalRegistrationName,
                LegalRegistrationNameEn: entity.LegalRegistrationNameEn,
                CompanyRegistrationNumber: entity.CompanyRegistrationNumber,
                CreatedAt: entity?.CreatedAt || '',
                IsActive: entity?.IsActive,
                EditButtons: entity.EntityId,
                ActionButtons: entity?.IsActive,
                VatRegistrationNumber: entity.VatRegistrationNumber,
            });
        });
        setEntityList(data);
    };

    useEffect(() => {
        if (!isLoading && entities) {
            formatEntityListData();
        }
    }, [entities]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = (e: any) => {
        e.stopPropagation();
        setIsModalOpen(true);
    };

    const navigate = useNavigate();
    const goToEntityUpdate = (e: any, entityId: string) => {
        e.stopPropagation();
        navigate(`/organizations/entity/update/${entityId}`);
    };

    const handleActive = (e: any, EntityId: string, IsActive: boolean) => {
        e.stopPropagation();
        setEntityId(EntityId);
        if (!IsActive) {
            setActiveModalOpen(true);
        } else {
            setDeactivateModalOpen(true);
        }
    };

    const onEntityDeactivate = () => {
        setButtonLoading(true);
        dispatch(disableEntity({ EntityId: entityId })).finally(() => {
            dispatch(getEntities(organizationId));
        });
        setButtonLoading(false);
        setDeactivateModalOpen(false);
    };

    const onEntityActivate = () => {
        setButtonLoading(true);
        dispatch(enableEntity({ EntityId: entityId })).finally(() => {
            dispatch(getEntities(organizationId));
        });
        setButtonLoading(false);
        setActiveModalOpen(false);
    };

    const columns: ColumnsType<IOrganizationEntityTableData> = [
        {
            title: `${t('ENTITY_ID')}`,
            dataIndex: 'EntityId',
            render: (text, data) => (
                <Tooltip title={data.EntityId}>
                    <span>{data.EntityId.slice(-5)}</span>
                </Tooltip>
            ),
        },
        {
            title: `${t('LEGAL_REGISTRATION_NAME')}`,
            dataIndex: ['LegalRegistrationName', 'LegalRegistrationNameEn'],
            render: (text, data) => (
                <Tooltip title={data.LegalRegistrationNameEn}>
                    <span>{data.LegalRegistrationName}</span>
                </Tooltip>
            ),
        },
        {
            title: `${t('VAT_REGISTRATION_NUMBER')}`,
            dataIndex: 'VatRegistrationNumber',
        },
        {
            title: `${t('COMPANY_REGISTRATION_NUMBER')}`,
            dataIndex: 'CompanyRegistrationNumber',
        },
        {
            title: `${t('CREATED_DATE')}`,
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },

        {
            title: `${t('STATUS')}`,
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
        {
            title: '',
            dataIndex: 'IsActive',
            render: (IsActive: boolean, data: IOrganizationEntityTableData) => (
                <div className='action-buttons'>
                    <div className='edit-table-icon' style={{ display: 'flex', gap: 10 }}>
                        {!IsActive && (
                            <Button
                                size='small'
                                type='primary'
                                disabled={data?.IsActive}
                                onClick={(e) => handleActive(e, data?.EntityId || '', IsActive)}
                            >
                                {t('ACTIVATE')}
                            </Button>
                        )}

                        {IsActive && (
                            <Button
                                size='small'
                                disabled={!data?.IsActive}
                                onClick={(e) => handleActive(e, data?.EntityId || '', IsActive)}
                            >
                                {t('DEACTIVATE')}
                            </Button>
                        )}
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (record: IOrganizationEntityTableData) => {
        navigate(`/organizations/${organizationId}/entity/${record.key}`);
    };

    const [filterList, setFilterList] = useState<any>();

    const handleFilterSubmit = (data: any) => {
        formatData(data);
    };
    const clearAllFilters = () => {
        setFilterList([]);
    };
    const formatData = (data: any) => {
        const keys = Object.keys(data);
        const filters: any[] = [];
        keys.forEach((key) => {
            if (data[key] && displayValues[key]) {
                filters.push({
                    key: displayValues[key],
                    value: key === 'dateRange' ? getFormatterDateRange(data[key]) : data[key],
                });
            }
        });
        setFilterList(filters);
    };
    const getFormatterDateRange = (dates: any) => {
        return (
            new Date(dates['startDate']).toDateString() +
            ' - ' +
            new Date(dates['endDate']).toDateString()
        );
    };
    const clearFilter = (item: any) => {
        const filterIndex = filterList.findIndex((filterID: any) => filterID.key === item.key);
        filterList.splice(filterIndex, 1);
        filterList.length > 0 ? setFilterList(filterList) : setFilterList([]);
    };

    const groupVat = organization?.VatRegistrationNumber[10] === '1'


    return (
        <div className='round-box-shadow'>
            <div className='space-for-search margin-30'>
                <div className='filter-header'>
                    <CoreSearchInput placeholder={t('SEARCH')} />
                    <FilterDropDown
                        content={<EntityFilterInputs handleFilterSubmit={handleFilterSubmit} />}
                    ></FilterDropDown>
                    <CoreButton
                        className='submit-button'
                        type='primary'
                        text={t('ADD_ENTITY')}
                        isFullWidth={true}
                        disabled={!groupVat && entities.length >= 1}
                        onClick={() => navigate(`/organizations/${organizationId}/entity/create`)}
                    />
                </div>

                {filterList && filterList.length > 0 && (
                    <Row className='filter-wrapper' justify='space-between'>
                        <Col span={20}>
                            {filterList.map((item: any, index: number) => (
                                <Tag
                                    key={index}
                                    className='margin-bottom-10'
                                    closable
                                    onClose={() => clearFilter(item)}
                                >
                                    <span>{item.key}</span>:{' '}
                                    <span className='filter-value'>{item.value}</span>
                                </Tag>
                            ))}
                        </Col>
                        <Col span={4}>
                            <Row justify='end'>
                                <div>
                                    <CoreButton
                                        type='basic'
                                        isFullWidth={false}
                                        text={t('CLEAR_ALL')}
                                        onClick={clearAllFilters}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
            <div className='ksa-table-wrapper'>
                <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={entityList}
                    rowClassName='cursor-pointer'
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => handleRowClick(record), // click row
                        };
                    }}
                    pagination={false}
                    scroll={{ x: 240 }}
                />
            </div>
            <Modal
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={400}
                footer={null}
                closable={false}
            >
                <InviteClient handleOk={handleOk} handleCancel={handleCancel} />
            </Modal>
            <ConfirmationModal
                title={t('DEACTIVATE_ENTITY')}
                description={t('WOULD_YOU_LIKE_TO_DEACTIVATE_THIS_ENTITY')}
                buttonType='Inactivate'
                buttonText={t('DEACTIVATE')}
                isDeleteModalOpen={isDeactivateModalOpen}
                handleDeleteOk={() => onEntityDeactivate()}
                handleDeleteCancel={() => setDeactivateModalOpen(false)}
                isLoading={isButtonLoading}
            />

            <ConfirmationModal
                title={t('ACTIVATE_ENTITY')}
                description={t('WOULD_YOU_LIKE_TO_ACTIVATE_THIS_ENTITY')}
                buttonType='activate'
                buttonText={t('ACTIVATE')}
                isDeleteModalOpen={isActiveModalOpen}
                handleDeleteOk={() => onEntityActivate()}
                handleDeleteCancel={() => setActiveModalOpen(false)}
                isLoading={isButtonLoading}
            />
        </div>
    );
};

export default OrganizationEntity;
