/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './AdminTemplateList.scss';
import { CoreButton, CoreTitle } from '@packages';
import { message, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ITemplate, ITemplateTable } from '@models/template.modle';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getAdminTemplates, templatesData } from "@store/slices/adminTemplateSlice";
import { ConfirmationModal } from "@components";
import { AdminTemplateAPI } from "@service/adminTemplate.service";

const AdminTemplateList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const { isLoading, data: templates } = useAppSelector(templatesData)

    const [templatesTableData, setTemplatesTableData] = useState<ITemplateTable[]>([])
    const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] = useState(false);
    const [templateId, setTemplateId] = useState('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getAdminTemplates({ PageLimit: 100 }),)
    }, [])

    useEffect(() => {
        if (!isLoading && templates) {
            formatTemplateData();
        }
    }, [templates]);


    const formatTemplateData = () => {
        const data: ITemplateTable[] = [];
        if (templates.length) {
            templates.forEach((template: ITemplate, index) => {
                data.push({
                    TemplateId: template.TemplateId,
                    TemplateName: template.TemplateName,
                    TemplateType: template.TemplateType,
                });
            });
        }
        setTemplatesTableData(data);
    };

    const columns: ColumnsType<ITemplateTable> = [
        {
            title: 'Template Id',
            dataIndex: 'TemplateId',
            render: (TemplateId: string) => (
                <>{TemplateId.slice(0, 5)}</>
            ),
        },
        {
            title: 'Template Name',
            dataIndex: 'TemplateName',
        },
        {
            title: 'Template Type',
            dataIndex: 'TemplateType',
        },
        {
            title: '',
            dataIndex: 'TemplateId',
            render: (TemplateId: string) => (
                <div className='action-buttons'>
                    <div
                        className='edit-table-icon'
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/template/update/${TemplateId}`);
                        }}
                    >
                        <EditOutlined />
                    </div>
                    <div className='edit-table-icon' onClick={(e) => handleDeleteClick(e, TemplateId)}>
                        <DeleteOutlined />
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (e: any, template: ITemplateTable) => {
        e.stopPropagation();
        navigate(`/template-details/${template?.TemplateId}`);
    };


    const handleDeleteClick = (e: any, TemplateId: string) => {
        e.stopPropagation();
        setIsDeleteTemplateModalOpen(true);
        setTemplateId(TemplateId);
    };

    const onTemplateDelete = async () => {
        if (!templateId) return;
        setButtonLoading(true);

        try {
            await AdminTemplateAPI.deleteAdminTemplate({ TemplateId: templateId });
            setButtonLoading(false);
            message.success(`Template Deleted Successfully`);
            setTemplateId('');
            dispatch(
                getAdminTemplates({ PageLimit: 100 }),
            );
            setIsDeleteTemplateModalOpen(false);
        } catch {
            message.error('Template Delete Failed');
            setButtonLoading(false);
        }
    };

    return (
        <div className='template-list-wrapper'>
            <div className='template-list-header'>
                <CoreTitle tag='h6' text='Templates' />
                <CoreButton
                    type='primary'
                    text='Add Template'
                    size='small'
                    onClick={() => navigate('/template-create')}
                />
            </div>

            <Table
                columns={columns}
                dataSource={templatesTableData}
                rowClassName='cursor-pointer'
                rowKey={'TemplateId'}
                onRow={(record) => {
                    return {
                        onClick: (e) => handleRowClick(e, record),
                    };
                }}
            />

            <ConfirmationModal
                title='Delete Template'
                description='Would you like to delete this Template?'
                buttonText='Delete'
                isDeleteModalOpen={isDeleteTemplateModalOpen}
                handleDeleteOk={() => onTemplateDelete()}
                handleDeleteCancel={() => setIsDeleteTemplateModalOpen(false)}
                isLoading={buttonLoading}
            />
        </div>
    );
};
export default AdminTemplateList;
