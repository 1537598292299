import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { RequestStatus } from '@models/async-status.enum';
import { IEntity } from '@models/organization.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EntityAPI } from '@service/entity.service';
import { RootState } from '@store/store';

interface IEntityState {
    activeEntity: {
        status: RequestStatus;
        data: IEntity[];
    };
}

const initialState: IEntityState = {
    activeEntity: {
        status: 'idle',
        data: [],
    },
};

export const getActiveEntities = createAsyncThunk('active-entities', async (orgId: string) => {
    try {
        const response = await EntityAPI.getActiveEntities(orgId);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const EntitySlice = createSlice({
    name: 'entity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getActiveEntities.pending, (state) => {
                state.activeEntity.status = 'loading';
            })
            .addCase(getActiveEntities.rejected, (state) => {
                state.activeEntity.status = 'failed';
                state.activeEntity.data = [];
            })
            .addCase(getActiveEntities.fulfilled, (state, action) => {
                state.activeEntity.status = 'idle';
                state.activeEntity.data = action.payload;
            });
    },
});

export const selectActiveEntityInfo = (state: RootState) => ({
    ...state.entity.activeEntity,
    data: state.entity.activeEntity.data.map((entity) => ({
        ...entity,
        value: entity?.EntityId,
        label: entity?.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
        hoverName: entity?.LegalRegistrationNameEn,
    })),
});
export default EntitySlice.reducer;
