import React, { useEffect, useState } from 'react';
import './ManageSubscription.scss';
import CardList from '../../components/card/CardList';
import { useAppDispatch } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import { CoreButton } from '@packages';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { SubscribeAPI } from '@service/subscription.service';
import { ISubscribe } from '@models/subscription.model';

export default function ManageSubscription() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tiredId = queryParams.get('id');
    const recurringType = queryParams.get('type');
    const navigate = useNavigate();

    const [cardId, setCardId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPaymentMethods());
    }, []);

    const onPaymentSubmit = async () => {
        if (!tiredId || !cardId || !recurringType) {
            message.warning('Your payment method, recurring mode or tire missing');
            return;
        }
        setLoading(true);

        try {
            const payload: ISubscribe = {
                Recurring: recurringType,
                TierId: tiredId,
                PaymentMethodId: cardId,
            };

            const response = await SubscribeAPI.subscribe(payload);
            message.success('Payment Successfully Created');
            setLoading(false);
            navigate('/subscribe/subscribe-success');
        } catch (error: any) {
            message.error(error?.Message);
            setLoading(false);
        }
    };
    return (
        <div className='container'>
            <div className='order-summary'>
                <h2>Payment summary</h2>
                <div className='order-details'>
                    <div className='subscription'>
                        <span className='icon'>🛡️</span>
                        <span className='text'>E Invoice Starter subscription</span>
                    </div>
                    <div className='price'>
                        <span className='duration'>24-months (USD 2.19/mo) + 3 months EXTRA</span>
                        <span className='discount'>Save 86%</span>
                        <span className='original-price'>USD 417.15</span>
                    </div>
                    <div className='total'>
                        <span>No VAT/Sales Tax</span>
                        <span className='location'>Bangladesh</span>
                        <span className='vat'>USD 0.00</span>
                    </div>
                    <div className='final-price'>
                        <span>Total</span>
                        <span>USD 59.13</span>
                    </div>
                    <div className='coupon'>
                        <span>Coupon code applied:</span>
                        <span className='coupon-code'>fullsecurity</span>
                        <span className='extra'>You've received: 3 months extra</span>
                    </div>
                </div>
            </div>
            <div className='payment-method'>
                <h2>ss method</h2>
                <CardList selectedCardId={cardId} setSelectedId={setCardId} />
            </div>

            <div className='footer-design'>
                <CoreButton
                    loading={loading}
                    type='primary'
                    text='Subscribe'
                    onClick={onPaymentSubmit}
                />
            </div>
        </div>
    );
}
