import { UpgradeIcon } from '@components/icons';
import { useNavigate } from 'react-router-dom';
import './SubscriptionUpgradePanel.scss';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useTranslation } from 'react-i18next';
import { logOutUser } from '@store/slices/authSlice';
import SubscriptionTrailModal from '@components/modals/subscription-trail-modal/SubscriptionTrailModal';
import { selectCurrentSubscriptions } from '@store/slices/subscribeSlice';
import { calculateTimeDifference } from '@utils/calculateDayDifference';
import { useGetMenuSelectedKey } from '@hooks';

const SubscriptionUpgradePanel = () => {
    const { t } = useTranslation();

    const menuKeys = useGetMenuSelectedKey();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { IsTrial, Expiration, TierName } = useAppSelector(selectCurrentSubscriptions) || {};
    const expireDayCount = calculateTimeDifference(Expiration);

    const isExpireSubscription = expireDayCount <= 0;
    const isSubscriptionPage = menuKeys.includes('subscribe');

    const logOutPortal = async () => {
        await dispatch(logOutUser());
    };

    const handleUpgrade = () => {
        navigate('/subscribe');
    };

    return (
        <>
            <div className='trail-panel'>
                {IsTrial ? (
                    <p>
                        {isExpireSubscription
                            ? 'Trail Expired'
                            : `${t('TRAIL_EXPIRES_IN')} ${expireDayCount} ${t('DAYS')}`}
                    </p>
                ) : (
                    <p>
                        {t('SUBSCRIPTION')}: {TierName}
                    </p>
                )}

                <div
                    className='upgrade-button'
                    onClick={() => navigate('/subscribe')}
                    aria-hidden='true'
                >
                    {t('CHANGE')}
                    <UpgradeIcon className='plus-button' />
                </div>
            </div>
            <SubscriptionTrailModal
                isSubscriptionTrailModalOpen={isExpireSubscription && !isSubscriptionPage}
                handleUpgrade={handleUpgrade}
                handelLogOut={logOutPortal}
            />
        </>
    );
};

export default SubscriptionUpgradePanel;
