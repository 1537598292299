import "./AdminTemplateDetails.scss"
import { CoreBackButton, CoreButton, CoreList, CoreTitle } from '@packages';
import { htmlToPdfGenerator } from "@utils/htmlToPdfGenerator";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect, useState } from "react";
import { getAdminSingleTemplate, singleTemplateData } from "@store/slices/adminTemplateSlice";
import { useParams } from "react-router-dom";
import { getTemplateInformation } from "@utils/helper";
import { useTranslation } from "react-i18next";

type CoreList = { key: string; value: string }[];

const AdminTemplateDetails = () => {
    const { t } = useTranslation();
    const { TemplateId } = useParams();
    const dispatch = useAppDispatch();
    const [templateInfoData, setTemplateInfo] = useState<CoreList>([])

    const { isLoading, data: template } = useAppSelector(singleTemplateData)

    useEffect(() => {
        dispatch(getAdminSingleTemplate({ TemplateId: TemplateId || '' }));
    }, [])

    useEffect(() => {
        if (template) {
            const templateInfo = getTemplateInformation(template, t);
            setTemplateInfo(templateInfo)
        }
    }, [template]);

    return (
        <div className="template-details-wrapper">
            <CoreBackButton text='' />
            <div className="template-details-inner-wrapper">
                <div className="template-details-header">
                    <CoreTitle tag='h4' text='Template Details' />
                    <CoreButton
                        type="primary"
                        text="Preview PDF"
                        size='small'
                        onClick={() => (htmlToPdfGenerator({ type: "preview", html: template?.PreviewHtml || "" }))}
                    />
                </div>
            </div>

            <div className="template-details-content">
                <CoreList listData={templateInfoData} />
            </div>
        </div>
    );
};

export default AdminTemplateDetails;
