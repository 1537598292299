import { useMemo, useState, useEffect } from 'react';
import { CoreButton, CoreStatus, CoreTitle } from '@packages';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, Col, Dropdown, MenuProps, message, Modal, Row, Table, Tooltip } from 'antd';
import './AddEntity.scss';
import { ICountry } from '@models/generic.model';
import countryList from 'react-select-country-list';
import {
    IAddEntityPayload,
    IEntity,
    IOrganizationEntityTableData,
} from '@models/organization.model';
import { RegexValidators } from '@config/regex-validator-constant';
import { useAppDispatch } from '@store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { getEntities, getOrganization } from '@store/slices/organizationSlice';
import { defaultCountryCode } from '@config/default-value';
import { CRS_OPTIONS } from '@utils/CRSOptions';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { LeftOutlined, MoreOutlined } from '@ant-design/icons';
import { OrganizationAPI } from '@service/organization.service';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import GenericPostalCode from '@components/common/GenericPostalCode';
import { useTranslation } from 'react-i18next';
import GenericStreetName from '@components/common/GenericStreetName';
import GenericInputMaxLimit from '@components/common/GenericInputMaxLimit';
import GenericLegalRegistrationName from '@components/common/GenericLegalRegistrationName';

const AddEntity = () => {
    const { t } = useTranslation();
    const [entityList, setEntityList] = useState<IOrganizationEntityTableData[]>([]);
    const [updateEntityId, setUpdateEntityId] = useState<string>('');
    const { isLoading, entities, organization } = useSelector(
        (state: RootState) => state.organization,
    );
    const dispatch = useAppDispatch();
    const { organizationId = '' } = useParams();
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);
    const groupVat = organization?.VatRegistrationNumber[10] === '1'



    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue: setFormValue,
        formState: { errors, isValid },
    } = useForm<IAddEntityPayload>({
        mode: 'onTouched',
        defaultValues: {
            TaxScheme: 'VAT',
            Address: {
                CountryCode: defaultCountryCode,
            },
        },
    });

    useEffect(() => {
        if (organizationId) {
            dispatch(getEntities(organizationId));
        }
    }, []);

    const formatEntityListData = () => {
        const data: IOrganizationEntityTableData[] = [];
        entities.forEach((entity: IEntity, index) => {
            data.push({
                key: index + 1 || '',
                EntityId: entity.EntityId,
                LegalRegistrationName: entity.LegalRegistrationName,
                LegalRegistrationNameEn: entity.LegalRegistrationNameEn,
                CompanyRegistrationNumber: entity.CompanyRegistrationNumber,
                CreatedAt: entity?.CreatedAt || '',
                IsActive: entity?.IsActive,
                VatRegistrationNumber: entity?.VatRegistrationNumber,
            });
        });
        setEntityList(data);
    };

    useEffect(() => {
        if (!isLoading && entities) {
            formatEntityListData();
        }
    }, [entities]);

    const clearForm = () => {
        reset({
            Address: {
                Street: '',
                StreetEn: '',
                BuildingNumber: '',
                AdditionalNumber: '',
                AdditionalStreet: '',
                AdditionalStreetEn: '',
                City: '',
                CityEn: '',
                CountryCode: defaultCountryCode,
                District: '',
                DistrictEn: '',
                PostalCode: '',
                ProvinceOrState: '',
                ProvinceOrStateEn: '',
            },
            Contact: {
                FirstName: '',
                FirstNameEn: '',
                JobTitle: '',
                JobTitleEn: '',
                LastName: '',
                LastNameEn: '',
                MiddleName: '',
                MiddleNameEn: '',
            },
            CompanyRegistrationNumber: '',
            CompanyRegistrationScheme: '',
            LegalRegistrationName: '',
            LegalRegistrationNameEn: '',
            TaxScheme: 'VAT',
            OrganizationId: '',
        });
    };
    const onSubmit = async (data: IAddEntityPayload) => {
        const prepareData = { ...data };
        prepareData.Address.AdditionalNumber = +data?.Address?.AdditionalNumber;
        prepareData.Address.BuildingNumber = +data?.Address?.BuildingNumber;
        prepareData.Address.PostalCode = +data?.Address?.PostalCode;
        prepareData.Address.CountryCode = data?.Address?.CountryCode || defaultCountryCode;

        const payload: IAddEntityPayload = {
            Address: prepareData.Address,
            Contact: prepareData.Contact,
            VatRegistrationNumber: prepareData.VatRegistrationNumber,
            CompanyRegistrationNumber: prepareData.CompanyRegistrationNumber,
            CompanyRegistrationScheme: prepareData.CompanyRegistrationScheme,
            LegalRegistrationName: prepareData.LegalRegistrationName,
            LegalRegistrationNameEn: prepareData.LegalRegistrationNameEn,
            TaxScheme: prepareData.TaxScheme,
            OrganizationId: organization?.OrganizationId || '',
            Email: prepareData.Email,
            Fax: prepareData.Fax,
        };
        if (updateEntityId) {
            payload.EntityId = updateEntityId;
            try {
                await OrganizationAPI.updateEntity(payload);
                message.success(t('ENTITY_UPDATED_SUCCESSFULLY'));
                await dispatch(getEntities(organizationId));
                await dispatch(getOrganization(organizationId));
            } catch {
                message.error(t('ENTITY_UPDATE_FAILED'));
            }
        } else {
            try {
                await OrganizationAPI.addEntity(payload);
                message.success(t('ENTITY_ADDED_SUCCESSFULLY'));
                await dispatch(getEntities(organizationId));
                await dispatch(getOrganization(organizationId));
            } catch {
                message.error(t('ENTITY_ADD_FAILED'));
            }
        }

        setFormValue('entityCheckBox', false)
        clearForm();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        if (isValid) {
            setIsModalOpen(false);
        }
        handleSubmit(onSubmit)();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        clearForm();
    };

    const handleDropdownItemClick = async (e: any, entityId: string) => {
        if (parseInt(e.key) === 1) {
            try {
                await OrganizationAPI.removeEntity({ EntityId: entityId });
                message.success(t('ENTITY_REMOVED_SUCCESSFULLY'));
                await dispatch(getEntities(organizationId));
            } catch {
                message.error(t('ENTITY_REMOVED_FAILED'));
            }
        }
        if (parseInt(e.key) === 2) {
            setUpdateEntityId(entityId);
            setIsModalOpen(true);
            const entity = entities.find((data) => data.EntityId === entityId);
            reset(entity);
        }
        if (parseInt(e.key) === 3) {
            try {
                await OrganizationAPI.enableEntity({ EntityId: entityId });
                message.success(t('ENTITY_ENABLED'));
                await dispatch(getEntities(organizationId));
            } catch {
                message.error(t('ENTITY_ENABLE_FAILED'));
            }
        }
        if (parseInt(e.key) === 4) {
            try {
                await OrganizationAPI.disableEntity({ EntityId: entityId });
                message.success(t('ENTITY_DISABLED'));
                await dispatch(getEntities(organizationId));
            } catch {
                message.error(t('ENTITY_DISABLE_FAILED'));
            }
        }
    };

    const createEntity = () => {
        dispatch(getOrganization(organizationId));
        setUpdateEntityId('');
        setIsModalOpen(true);
    };

    const columns: ColumnsType<IOrganizationEntityTableData> = [
        {
            title: '',
            dataIndex: 'key',
            align: 'center',
        },
        {
            title: t('LEGAL_REGISTRATION_NAME'),
            dataIndex: ['LegalRegistrationName', 'LegalRegistrationNameEn'],
            render: (text, data) => (
                <Tooltip title={data.LegalRegistrationNameEn}>
                    <span>{data.LegalRegistrationName}</span>
                </Tooltip>
            ),
        },
        {
            title: t('VAT_REGISTRATION_NUMBER'),
            dataIndex: 'VatRegistrationNumber',
        },
        {
            title: t('COMPANY_REGISTRATION_NUMBER'),
            dataIndex: 'CompanyRegistrationNumber',
        },
        {
            title: t('CREATED_DATE'),
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: t('ACTIVE_STATUS'),
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
        {
            title: '',
            dataIndex: 'EntityId',
            render: (entityId: string, data) => {
                const items: MenuProps['items'] = [
                    // {
                    //     key: '1',
                    //     label: 'Remove',
                    // },
                    {
                        key: '2',
                        label: t('UPDATE'),
                    },
                    ...(!data.IsActive
                        ? [
                            {
                                key: '3',
                                label: t('ENABLE'),
                            },
                        ]
                        : [
                            {
                                key: '4',
                                label: t('DISABLE'),
                            },
                        ]),
                ];
                return (
                    <div className='action-buttons'>
                        <Dropdown
                            menu={{
                                items,
                                onClick: (e) => {
                                    handleDropdownItemClick(e, entityId);
                                },
                            }}
                            trigger={['click']}
                            arrow
                            placement='bottomRight'
                        >
                            <div className='dropdown-icon-button'>
                                <MoreOutlined />
                            </div>
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const navigate = useNavigate();

    const next = () => {
        OrganizationAPI.postOrganizationWorkflow(organizationId).then(() => {
            navigate(`/organization-setup/${organizationId}/invoice-phase`);
        });
    };
    const prev = () => {
        navigate(`/organization-setup/${organizationId}/organization-information`, {
            state: {
                updateOrganization: true,
            },
        });
    };

    const saveAndClose = () => {
        navigate(`/organizations`);
    };


    const handelCheckBox = (e: any) => {
        // setEntityCheckBoxValue(e.target.checked);
        setFormValue('entityCheckBox', e.target.checked)
        if (e.target.checked === true) {
            setFormValue('LegalRegistrationName', organization?.LegalRegistrationName || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('LegalRegistrationNameEn', organization?.LegalRegistrationNameEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('VatRegistrationNumber', organization?.VatRegistrationNumber || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue(
                'CompanyRegistrationScheme',
                organization?.CompanyRegistrationScheme || '',
                {
                    shouldValidate: true,
                    shouldDirty: true,
                },
            );
            setFormValue(
                'CompanyRegistrationNumber',
                organization?.CompanyRegistrationNumber || '',
                {
                    shouldValidate: true,
                    shouldDirty: true,
                },
            );
            setFormValue('Address.BuildingNumber', organization?.Address?.BuildingNumber || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.Street', organization?.Address?.Street || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.StreetEn', organization?.Address?.StreetEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue(
                'Address.AdditionalNumber',
                organization?.Address?.AdditionalNumber || '',
                {
                    shouldValidate: true,
                    shouldDirty: true,
                },
            );
            setFormValue(
                'Address.AdditionalStreet',
                organization?.Address?.AdditionalStreet || '',
                {
                    shouldValidate: true,
                    shouldDirty: true,
                },
            );
            setFormValue(
                'Address.AdditionalStreetEn',
                organization?.Address?.AdditionalStreetEn || '',
                {
                    shouldValidate: true,
                    shouldDirty: true,
                },
            );
            setFormValue('Address.City', organization?.Address?.City || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.CityEn', organization?.Address?.CityEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.District', organization?.Address?.District || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.DistrictEn', organization?.Address?.DistrictEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.PostalCode', organization?.Address?.PostalCode || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.ProvinceOrState', organization?.Address?.ProvinceOrState || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.ProvinceOrStateEn', organization?.Address?.ProvinceOrStateEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Address.CountryCode', organization?.Address?.CountryCode || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.FirstName', organization?.Contact?.FirstName || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.FirstNameEn', organization?.Contact?.FirstNameEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.LastName', organization?.Contact?.LastName || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.LastNameEn', organization?.Contact?.LastNameEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.MiddleName', organization?.Contact?.MiddleName || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.MiddleNameEn', organization?.Contact?.MiddleNameEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.JobTitle', organization?.Contact?.JobTitle || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Contact.JobTitleEn', organization?.Contact?.JobTitleEn || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Email', organization?.Email || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
            setFormValue('Fax', organization?.Fax || '', {
                shouldValidate: true,
                shouldDirty: true,
            });
        } else {
            reset();
        }
    };

    return (
        <>
            <div className='step-title-area'>
                <div className='left-area'>
                    <CoreButton text='' onClick={prev} icon={<LeftOutlined />} />
                    <div className='content'>
                        <CoreTitle tag='h6' text={t('ADD_ORGANIZATION_DETAILS')} />
                        <p>{t('STEP_2_of_5')}</p>
                    </div>
                </div>
                <div className='step-button-group'>
                    <CoreButton text={t('CLOSE')} onClick={saveAndClose} />
                    <CoreButton type='primary' onClick={next} text={t('CONTINUE')} />
                </div>
            </div>

            <div className='step-area'>
                <div className='stepper-content'>
                    <div className='add-entity'>
                        <CoreButton
                            className='add-button'
                            onClick={createEntity}
                            text={t('ADD_ENTITY')}
                            disabled={!groupVat && entities.length >= 1}
                        />
                        <Table
                            loading={isLoading}
                            columns={columns}
                            dataSource={entityList}
                            rowClassName='cursor-pointer'
                            pagination={false}
                        />
                        <Modal
                            width={890}
                            open={isModalOpen}
                            onOk={handleOk}
                            okText={t('SAVE')}
                            onCancel={handleCancel}
                            cancelText={t('CANCEL')}
                            maskClosable={false}
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='entity-title-area'>
                                    <CoreTitle
                                        tag='h5'
                                        text={updateEntityId ? t('UPDATE_ENTITY') : t('ADD_ENTITY')}
                                    />
                                    <p>{t('PLEASE_ENTER_ENTITY_DETAILS')}</p>
                                </div>

                                <div className='general-input-wrapper'>
                                    <Controller
                                        render={({ field }) => (
                                            <Checkbox
                                                className='general-checkbox'
                                                onChange={handelCheckBox}
                                                checked={watch('entityCheckBox')}
                                            >
                                                {t('APPLY_SAME_INFORMATION_IN_ORGANIZATION_STEP_1')}
                                            </Checkbox>
                                        )}
                                        control={control}
                                        name='entityCheckBox'
                                    />
                                </div>

                                <CoreTitle tag='h4' text={t('ENTITY_INFORMATION')} />
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <GenericLegalRegistrationName
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='LegalRegistrationName'
                                            level={t('LEGAL_REGISTRATION_NAME_ARABIC')}
                                            marginBottom={20}
                                            type='arabic'
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t(
                                                        'ONLY_ARBIC_TEXT_IS_ALLOWED_FOR_LEGAL_REGISTRATION_NAME',
                                                    ),
                                                },
                                                maxLength: 1000,
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <GenericLegalRegistrationName
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='LegalRegistrationNameEn'
                                            level={t('LEGAL_REGISTRATION_NAME_ENGLISH')}
                                            marginBottom={20}
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t(
                                                        'ONLY_ENGLISH_TEXT_IS_ALLOWED_FOR_LEGAL_REGISTRATION_NAME',
                                                    ),
                                                },
                                                maxLength: 1000,
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericSelect
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='CompanyRegistrationScheme'
                                            level={t('OTHER_SELLER_ID_TYPE')}
                                            placeHolder={t('OTHER_SELLER_ID_TYPE')}
                                            options={CRS_OPTIONS}
                                            setFormValue={setFormValue}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='CompanyRegistrationNumber'
                                            level={t('OTHER_SELLER_ID')}
                                            marginBottom={20}
                                            type='otherSellerId'
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: RegexValidators.ONLY_NUMERIC,
                                                    message: t('OTHER_SELLER_ID_NUMERIC'),
                                                },
                                                maxLength: 10,
                                            }}
                                            disabled={
                                                watch('CompanyRegistrationScheme') === undefined ||
                                                watch('CompanyRegistrationScheme') === ''
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            tooltip={t(
                                                'VAT_REGISTRATION_NUMBER_REFERS_TO_A_UNIQUE_IDENTIFICATION_NUMBER_ASSIGNED_TO_A_BUSINESS',
                                            )}
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='VatRegistrationNumber'
                                            level={t('VAT_REGISTRATION_NUMBER')}
                                            marginBottom={20}
                                            type='vatRegistrationNumber'
                                            rules={{
                                                required: true,
                                                pattern: RegexValidators.VAT_REGISTRATION_NUMBER,
                                            }}
                                            hideError={true}
                                        >
                                            {errors.VatRegistrationNumber?.type === 'required' && (
                                                <span className='error-message'>
                                                    {t('VAT_REGISTRATION_NUMBER_IS_REQUIRED')}
                                                </span>
                                            )}

                                            {errors.VatRegistrationNumber?.type === 'pattern' && (
                                                <span className='error-message'>
                                                    {t('VAT_REGISTRATION_NUMBER_PATTERN_ERROR')}
                                                </span>
                                            )}
                                        </InlineGenericInput>
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='TaxScheme'
                                            level={t('TAX_SCHEME')}
                                            marginBottom={20}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <CoreTitle tag='h4' text={t('POSTAL_ADDRESS')} />
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.BuildingNumber'
                                            level={t('BUILDING_NUMBER')}
                                            marginBottom={20}
                                            type='buildingNumber'
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: RegexValidators.ONLY_NUMERIC,
                                                    message: t('BUILDING_NUMBER_SHOULD_BE_NUMERIC'),
                                                },
                                                maxLength: {
                                                    value: 4,
                                                    message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS'),
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS'),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <GenericStreetName
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.Street'
                                            level={t('STREET_ARABIC')}
                                            marginBottom={20}
                                            type='arabic'
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t(
                                                        'ONLY_ARABIC_TEXT_IS_ALLOWED_IN_STREET',
                                                    ),
                                                },
                                                maxLength: 1000,
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.AdditionalNumber'
                                            level={t('ADDITIONAL_NUMBER')}
                                            rules={{
                                                maxLength: {
                                                    value: 4,
                                                    message: t(
                                                        'ADDITIONAL_NUMBER_MUST_BE_4_DIGITS',
                                                    ),
                                                },
                                            }}
                                            marginBottom={20}
                                            type='number'
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <GenericStreetName
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.StreetEn'
                                            level={t('STREET_ENGLISH')}
                                            marginBottom={20}
                                            type='english'
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t(
                                                        'ONLY_ENGLISH_TEXT_IS_ALLOWED_IN_STREET',
                                                    ),
                                                },
                                                maxLength: 1000,
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.AdditionalStreet'
                                            level={t('ADDITIONAL_STREET_ARABIC')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='arabic'
                                            rules={{
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t(
                                                        'ONLY_ARABIC_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.AdditionalStreetEn'
                                            level={t('ADDITIONAL_STREET_ENGLISH')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='english'
                                            rules={{
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t(
                                                        'ONLY_ENGLISH_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.City'
                                            level={t('CITY_ARABIC')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='arabic'
                                            rules={{
                                                required: true,
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t(
                                                        'ONLY_ARABIC_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.CityEn'
                                            level={t('CITY_ENGLISH')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='english'
                                            rules={{
                                                required: true,
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t(
                                                        'ONLY_ENGLISH_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.District'
                                            level={t('DISTRICT_ARABIC')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='arabic'
                                            rules={{
                                                required: true,
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t(
                                                        'ONLY_ARABIC_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.DistrictEn'
                                            level={t('DISTRICT_ENGLISH')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='english'
                                            rules={{
                                                required: true,
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t(
                                                        'ONLY_ENGLISH_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.ProvinceOrState'
                                            level={t('PROVINCE_ARABIC')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='arabic'
                                            rules={{
                                                required: true,
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t(
                                                        'ONLY_ARABIC_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <GenericInputMaxLimit
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.ProvinceOrStateEn'
                                            level={t('PROVINCE_ENGLISH')}
                                            marginBottom={20}
                                            maxLimit={127}
                                            type='english'
                                            rules={{
                                                required: true,
                                                maxLength: 127,
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t(
                                                        'ONLY_ENGLISH_TEXT_IS_ALLOWED',
                                                    ),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <GenericPostalCode
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.PostalCode'
                                            level={t('POSTAL_CODE')}
                                            marginBottom={20}
                                            rules={{
                                                required: true,
                                                minLength: {
                                                    value: 5,
                                                    message:
                                                        'Postal Code minimum length is 5 digit',
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericSelect
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Address.CountryCode'
                                            level={t('COUNTRY')}
                                            placeHolder={t('SELECT_COUNTRY')}
                                            options={countryOptions}
                                            rules={{
                                                required: true,
                                            }}
                                            setFormValue={setFormValue}
                                        />
                                    </Col>
                                </Row>

                                <CoreTitle tag='h4' text={t('CONTACT')} />
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.FirstName'
                                            level={t('FIRST_NAME_ARABIC')}
                                            marginBottom={20}
                                            type='arabic'
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.FirstNameEn'
                                            level={t('FIRST_NAME_ENGLISH')}
                                            marginBottom={20}
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.LastName'
                                            level={t('LAST_NAME_ARABIC')}
                                            marginBottom={20}
                                            type='arabic'
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.LastNameEn'
                                            level={t('LAST_NAME_ENGLISH')}
                                            marginBottom={20}
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.MiddleName'
                                            level={t('MIDDLE_NAME_ARABIC')}
                                            marginBottom={20}
                                            type='arabic'
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.MiddleNameEn'
                                            level={t('MIDDLE_NAME_ENGLISH')}
                                            marginBottom={20}
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.JobTitle'
                                            level={t('JOB_TITLE_ARABIC')}
                                            marginBottom={20}
                                            type='arabic'
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ARABIC_CHARACTER,
                                                    message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Contact.JobTitleEn'
                                            level={t('JOB_TITLE_ENGLISH')}
                                            marginBottom={20}
                                            rules={{
                                                pattern: {
                                                    value: RegexValidators.ENGLISH_CHARACTER,
                                                    message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                                },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            dataKey='Email'
                                            level={t('EMAIL_ADDRESS')}
                                            marginBottom={20}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: t('EMAIL_FIELD_IS_REQUIRED'),
                                                },
                                                pattern: {
                                                    value: RegexValidators.EMAIL,
                                                    message: t('EMAIL_WARNING'),
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <InlineGenericInput
                                            control={control}
                                            errors={errors}
                                            levelCol={24}
                                            inputCol={24}
                                            type='number'
                                            dataKey='Fax'
                                            level={t('FAX')}
                                            marginBottom={20}
                                        />
                                    </Col>
                                </Row>
                            </form>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddEntity;
