import { useEffect } from 'react';
import { CoreButton, CoreSearchInput } from '@packages';
import './OrganizationList.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { getOrganizations } from '@store/slices/organizationSlice';
import { useAppDispatch } from '@store/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrganizationListTable } from '../../index';

const displayValues: any = {
    legalRegistrationName: 'Legal Registration Name',
    vatRegistrationNumber: 'VAT Registration Number',
    organizationLegacyId: 'Organization Legacy ID',
    dateRange: 'Date Range',
    activeStatus: 'Active Status',
    organizationRegistrationScheme: 'Organization Registration Scheme',
};
const OrganizationList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { orgFilters } = useSelector((state: RootState) => state.organization);
    const { detailsData } = useSelector((state: RootState) => state.subscribe);
    const { organizations } = useSelector((state: RootState) => state.organization);

    useEffect(() => {
        formatData();
    }, [orgFilters]);

    useEffect(() => {
        dispatch(getOrganizations());
    }, []);

    const navigate = useNavigate();

    const formatData = () => {
        const filters: any[] = [];
        for (const key in orgFilters) {
            // @ts-ignore
            const filterItem = orgFilters[key];
            if (filterItem) {
                filters.push({
                    key: key,
                    displayText: displayValues[key],
                    value: key === 'dateRange' ? getFormatterDateRange(filterItem) : filterItem,
                });
            }
        }
    };
    const getFormatterDateRange = (dates: any) => {
        return (
            new Date(dates['startDate']).toDateString() +
            ' - ' +
            new Date(dates['endDate']).toDateString()
        );
    };

    return (
        <div className='organization-list-container'>
            <div className='filter-header margin-30'>
                <CoreSearchInput placeholder={t('SEARCH')} />
                <CoreButton
                    className='submit-button'
                    type='primary'
                    text={t('CREATE_NEW')}
                    isFullWidth={true}
                    onClick={() => navigate('/organizations/create')}
                    disabled={detailsData?.PlanName != 'ELITE' && organizations?.length >= 1}
                />
            </div>
            <div className='margin-top-20'>
                <OrganizationListTable />
            </div>
        </div>
    );
};

export default OrganizationList;
