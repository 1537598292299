/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, Navigate } from 'react-router-dom';
import { RootState } from '@store/store';
import { FC, useEffect } from 'react';
import { FetchLoggedUserRequest } from '@store/slices/userSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { isLoginUser } from '@store/slices/authSlice';
import { useFetchInitialAppUserData } from '@hooks';

interface IRequireAuthProps {
    allowedRoles?: string[];
}

const RequireAuth: FC<IRequireAuthProps> = ({ allowedRoles }) => {
    const isLogin = useAppSelector(isLoginUser);
    const { Roles } = useAppSelector((state: RootState) => state.user.userDetails);
    const dispatch = useAppDispatch();
    const getLoggedUserInfo = async () => {
        dispatch(FetchLoggedUserRequest());
    };

    useFetchInitialAppUserData(); // after login fetch app user data

    useEffect(() => {
        isLogin && getLoggedUserInfo();
    }, [isLogin]);

    if (isLogin === false) {
        return <Navigate replace to='/login' />;
    }
    const isAuthorize = isLogin && Roles?.some((data) => allowedRoles?.includes(data));
    if (isLogin && !isAuthorize) {
        return <></>;
    }

    return isAuthorize ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />;
};

export default RequireAuth;
