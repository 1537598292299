import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, MenuProps, Row, Spin, Table, Tabs, TabsProps, message } from 'antd';
import { CoreBackButton, CoreTitle, CoreList, CoreStatus } from '@packages';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './InvoiceDetails.scss';
import { ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    getInvoice,
    isLoadingSingleInvoice,
    resetSingleInvoice,
} from '@store/slices/einvoiceSlice';
import { UtcToLocalDate } from '@utils/date';
import { getEntity } from '@store/slices/organizationSlice';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { getCustomer, resetSingleCustomer } from '@store/slices/customerSlice';
import { fileStorageAPI } from '@service/file-storage.service';
import XmlView from '@components/file-view/XmlView';
import PdfView from '@components/file-view/PdfView';
import { useTranslation } from 'react-i18next';
import {
    EInvoiceTypeCode,
    GET_INVOICE_TYPE_CODE_BY_TYPE_CODE,
    GET_SIMPLIFIED_INVOICE_TYPES,
    GET_STANDARD_INVOICE_TYPES,
} from '@utils/InvoiceUtils';
import { displayCurrency } from '@utils/displayCurrency';
import { EInvoiceType, IInvoiceState } from '@models/invoice.model';
import SupplyInformation from '../../components/supply-informatin/SupplyInformation';
import CustomerInformation from '../../components/customer-information/CustomerInformation';
import { reportIconSVG } from '@assets/svg';
import { flattenData } from '@utils/flatListData';
import JsonViewer from '@components/file-view/JsonViewer';
import { getFixedInvoiceType } from '@utils/InvoiceTypeSelectOption';
import {
    getChargeDiscountAdditionValue,
    getTotalChargeValue,
    getTotalDiscountValue,
} from '@utils/InvoiceCalculation';

type CoreList = { key: string; value: string }[];

interface IEinvoiceLineTableData {
    Id: number;
    Quantity: number | string;
    UnitCode: string;
    TaxAmount: number | string;
    TaxCategory: string;
    TaxExemptionCode: string;
    RoundingAmount: number | string;
    ItemName: string;
    TaxPercent: number | string;
    PriceAmount: number | string;
}

const InvoiceDetails = () => {
    const { t } = useTranslation();
    const { invoice } = useSelector((state: RootState) => state.einvoice);
    const isLoading = useAppSelector(isLoadingSingleInvoice);
    const [invoiceLines, setInvoiceLines] = useState<IEinvoiceLineTableData[]>([]);
    const { invoiceId = '' } = useParams();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isViewing, setViewing] = useState<boolean>(false);
    const { organization, entity } = useSelector((state: RootState) => state.organization);

    const [xmlUrl, setXmlUrl] = useState<string>();
    const [pdfUrl, setPDFUrl] = useState<string>();
    const [isXmlModalOpen, setXmlModalOpen] = useState<boolean>(false);
    const [isJsonModalOpen, setJsonModalOpen] = useState<boolean>(false);
    const [isPdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
    const [xmlText, setXmlText] = useState<string>();
    const [raiseInvoiceNote, setRaiseInvoiceNote] = useState<MenuProps['items']>([]);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getInvoice(invoiceId));
        return () => {
            dispatch(resetSingleInvoice());
            dispatch(resetSingleCustomer());
        };
    }, []);

    useEffect(() => {
        if (invoice?.EntityId) {
            dispatch(getEntity(invoice?.EntityId));
        }
    }, [invoice?.EntityId]);

    useEffect(() => {
        if (invoice?.CustomerId) {
            dispatch(getCustomer(invoice?.CustomerId));
        }
    }, [invoice?.CustomerId]);

    useEffect(() => {
        const menuProps = getNoteDropDown();
        setRaiseInvoiceNote(menuProps);
    }, [invoice, t]);

    useEffect(() => {
        prepareInvoiceLinesItem();
    }, [invoice?.InvoiceLines]);

    const onDownloadItem = async (itemId: string, fileName: 'XML' | 'PDF') => {
        if (fileName === 'XML' && xmlUrl) return xmlUrl;
        if (fileName === 'PDF' && pdfUrl) return pdfUrl;
        try {
            const response = await fileStorageAPI.getFileUrl(itemId);
            fileName === 'XML' && setXmlUrl(response);
            fileName === 'PDF' && setPDFUrl(response);
            return response;
        } catch (error) {
            setIsDownloading(false);
            setViewing(false);
            message.error('File information not found ');
            throw error;
        }
    };

    const columns: ColumnsType<IEinvoiceLineTableData> = [
        {
            title: t('ID'),
            dataIndex: 'Id',
        },
        {
            title: t('ITEM_NAME'),
            dataIndex: 'ItemName',
        },
        {
            title: t('QUANTITY'),
            dataIndex: 'Quantity',
        },
        {
            title: t('SALE_PRICE'),
            dataIndex: 'PriceAmount',
            render: (text: string, data) => (
                <span>
                    {(data?.PriceAmount &&
                        displayCurrency({
                            Amount: data?.PriceAmount,
                            Code: `${invoice?.DocumentCurrencyCode}`,
                        })) ??
                        DEFAULT_EMPTY_VALUE}
                </span>
            ),
        },
        {
            title: t('UNIT_OF_MEASURE'),
            dataIndex: 'UnitCode',
        },
        {
            title: t('VAT_RATE'),
            dataIndex: 'TaxPercent',
            render: (text: string, data) => {
                return <span>{data?.TaxPercent ?? DEFAULT_EMPTY_VALUE}%</span>;
            },
        },
        {
            title: t('VAT_AMOUNT'),
            dataIndex: 'TaxAmount',
            render: (text: string, data) => (
                <span>
                    {(data?.TaxAmount &&
                        displayCurrency({
                            Amount: isNaN(data?.TaxAmount as number) ? 0 : data?.TaxAmount,
                            Code: 'SAR',
                        })) ||
                        DEFAULT_EMPTY_VALUE}
                </span>
            ),
        },
        {
            title: t('ROUNDING_AMOUNT'),
            dataIndex: 'RoundingAmount',
            render: (text: string, data) => (
                <span>
                    {displayCurrency({
                        Amount: isNaN(data?.RoundingAmount as number) ? 0 : data?.RoundingAmount,
                        Code: `${invoice?.DocumentCurrencyCode}`,
                    })}
                </span>
            ),
        },
    ];
    const invoiceStatus = invoice?.IsXmlFileGenerated ? (
        <CoreStatus type='success' status={t('GENERATED')} />
    ) : (
        <CoreStatus type='inactive' status={t('FAILED')} />
    );

    const zatkaStatus =
        invoice?.ZatcaStatus?.toUpperCase() == 'CLEARED' ? (
            <CoreStatus type='success' status='CLEARED' />
        ) : invoice?.ZatcaStatus?.toUpperCase() === 'FAILED' ? (
            <CoreStatus type='failed' status={t('FAILED')} />
        ) : (
            <CoreStatus type='warning' status={t('CLEARED_WITH_WARNING')} />
        );

    const readXmlFile = async () => {
        if (!invoice?.XmlFile.FileId) return;

        if (xmlText) {
            setXmlModalOpen(true);
            return;
        }
        setViewing(true);
        const fileUrl = await onDownloadItem(invoice?.XmlFile.FileId, 'XML');
        fileStorageAPI.readFileFromURL(fileUrl).then((response) => {
            setXmlText(response);
            setXmlModalOpen(true);
        });
        setViewing(false);
    };

    const readJsonFile = async () => {
        setJsonModalOpen(true);
    };

    const readPdfFile = async () => {
        if (!invoice?.PdfFile.FileId) return;
        if (pdfUrl) {
            setPdfModalOpen(true);
            return;
        }
        setViewing(true);
        const fileUrl = await onDownloadItem(invoice?.PdfFile.FileId, 'PDF');
        setPDFUrl(fileUrl);
        setPdfModalOpen(true);
        setViewing(false);
    };

    const itemsDownloads: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link
                    to=''
                    onClick={async () => {
                        if (!invoice?.XmlFile?.FileId) return;
                        setIsDownloading(true);
                        const file = await onDownloadItem(invoice?.XmlFile.FileId, 'XML');
                        window.open(file, '_blank');
                        setIsDownloading(false);
                    }}
                >
                    <div className='drop-down-item'>{t('DOWNLOAD_XML')}</div>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link
                    to=''
                    onClick={async () => {
                        if (!invoice?.PdfFile?.FileId) return;
                        setIsDownloading(true);
                        const file = await onDownloadItem(invoice?.PdfFile.FileId, 'PDF');
                        setIsDownloading(false);
                        window.open(file, '_blank');
                    }}
                >
                    <div className='drop-down-item'>{t('DOWNLOAD_PDF')}</div>
                </Link>
            ),
        },
    ];

    const itemsViews: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to='' onClick={readXmlFile}>
                    <div className='drop-down-item'>{t('VIEW_XML')}</div>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to='' onClick={readPdfFile}>
                    <div className='drop-down-item'>{t('VIEW_PDF')}</div>
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link to='' onClick={readJsonFile}>
                    <div className='drop-down-item'>{t('VIEW_ZATCA_RESPONSE')}</div>
                </Link>
            ),
        },
    ];

    const getNoteDropDown = (): MenuProps['items'] => {
        if (!invoice) return [];
        const { InvoiceType, InvoiceTypeCode } = invoice;
        if (!InvoiceType && !InvoiceTypeCode) return [];
        const InvoiceTypeInfo = GET_INVOICE_TYPE_CODE_BY_TYPE_CODE(InvoiceType, InvoiceTypeCode);
        if (!InvoiceTypeInfo) return [];

        let invoiceNote: { value: string; label: string; displayKey: string }[] = [];
        if (InvoiceTypeInfo.name === EInvoiceType['Standard Tax Invoice']) {
            const flattenStandardData = flattenData(GET_STANDARD_INVOICE_TYPES);
            invoiceNote = flattenStandardData.filter((standard) =>
                standard.label?.toLocaleLowerCase().includes('note'),
            );
        }
        if (InvoiceTypeInfo.name === EInvoiceType['Simplified Tax Invoice']) {
            const flattenSimplifiedData = flattenData(GET_SIMPLIFIED_INVOICE_TYPES);
            invoiceNote = flattenSimplifiedData.filter((simplified) =>
                simplified.label?.toLocaleLowerCase().includes('note'),
            );
        }
        return invoiceNote.map((note) => ({
            key: note.value,
            label: (
                <div
                    onClick={() =>
                        navigate('/create-' + note?.value?.toLowerCase()?.split('_').join('-'), {
                            state: {
                                InvoiceType: note.value,
                                InvoiceTypeCode: invoice?.InvoiceTypeCode,
                                OrganizationId: invoice?.OrganizationId,
                                DeviceId: invoice?.DeviceId,
                                InvoicePhase: organization?.InvoicePhase || 2,
                                EntityId: invoice?.EntityId,
                                CustomerId: invoice?.CustomerId,
                                Reference: {
                                    ID: invoice.InvoiceId,
                                    IssueDatetime: invoice?.IssueDateTime,
                                    FileId: invoice?.PdfFile?.FileId,
                                },
                                linesItems: invoice?.InvoiceLines || [],
                                isGenerateCreditNote: note.label
                                    ?.toLocaleLowerCase()
                                    .includes('credit'),
                                totalAmount: invoice?.LegalMonetaryTotal?.TaxInclusiveAmount,
                            } as IInvoiceState,
                        })
                    }
                    className='drop-down-item'
                    aria-hidden='true'
                >
                    {t(note.displayKey)}
                </div>
            ),
        }));
    };

    const prepareInvoiceLinesItem = () => {
        const lines: IEinvoiceLineTableData[] = [];
        invoice?.InvoiceLines.forEach((data) => {
            lines.push({
                Id: data.ID,
                Quantity: data.Quantity || DEFAULT_EMPTY_VALUE,
                UnitCode: data.UnitCode || DEFAULT_EMPTY_VALUE,
                TaxAmount: data.TaxAmount || DEFAULT_EMPTY_VALUE,
                TaxCategory: data.TaxCategory || DEFAULT_EMPTY_VALUE,
                RoundingAmount: data.RoundingAmount || DEFAULT_EMPTY_VALUE,
                ItemName: data.ItemName || DEFAULT_EMPTY_VALUE,
                TaxPercent: data.TaxPercent ?? DEFAULT_EMPTY_VALUE,
                PriceAmount: data.PriceAmount ?? DEFAULT_EMPTY_VALUE,
                TaxExemptionCode: data.TaxExemptionCode ?? DEFAULT_EMPTY_VALUE,
            });
        });
        setInvoiceLines(lines);
    };
    const paymentMeansTableData = [
        {
            key: `${t('PAYMENT_MEANS_CODE')}:`,
            value: invoice?.PaymentMeans?.PaymentMeansCode || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PAYMENT_TERMS')}:`,
            value: invoice?.PaymentMeans?.PaymentTerms || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('PAYMENT_CHANNEL_CODE')}:`,
            value: invoice?.PaymentMeans?.PaymentChannelCode || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('SUPPLIER_BANK_ACCOUNT_NUMBER')}:`,
            value: invoice?.PaymentMeans?.PaymentAccountIdentifier || DEFAULT_EMPTY_VALUE,
        },
    ];

    const deliveryDateTableData = [
        {
            key: `${t('SUPPLY_DATE')}:`,
            value: UtcToLocalDate(invoice?.Delivery?.ActualDeliveryDate) || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('SUPPLY_DELIVERY_TIME')}:`,
            value: invoice?.Delivery?.ActualDeliveryTime || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('SUPPLY_END_DATE')}:`,
            value: invoice?.Delivery?.LatestDeliveryDate || DEFAULT_EMPTY_VALUE,
        },
    ];

    const legalMonetaryDataTable = [
        {
            key: `${t('LINE_EXTENSION_AMOUNT')}:`,
            value: invoice?.LegalMonetaryTotal?.LineExtensionAmount
                ? displayCurrency({
                      Amount: invoice?.LegalMonetaryTotal?.LineExtensionAmount,
                      Code: invoice.DocumentCurrencyCode,
                  })
                : displayCurrency({ Amount: 0, Code: invoice?.DocumentCurrencyCode || '' }),
        },
        {
            key: `${t('TAX_EXCLUSIVE_AMOUNT')}:`,
            value: invoice?.LegalMonetaryTotal?.TaxExclusiveAmount
                ? displayCurrency({
                      Amount: invoice?.LegalMonetaryTotal?.TaxExclusiveAmount,
                      Code: invoice.DocumentCurrencyCode,
                  })
                : displayCurrency({ Amount: 0, Code: invoice?.DocumentCurrencyCode || '' }),
        },
        {
            key: `${t('TAX_INCLUSIVE_AMOUNT')}:`,
            value: invoice?.LegalMonetaryTotal?.TaxInclusiveAmount
                ? displayCurrency({
                      Amount: invoice?.LegalMonetaryTotal?.TaxInclusiveAmount,
                      Code: invoice.DocumentCurrencyCode,
                  })
                : displayCurrency({ Amount: 0, Code: invoice?.DocumentCurrencyCode || '' }),
        },
        {
            key: `${t('LINE_LEVEL_DISCOUNT')}:`,
            value: invoice?.LegalMonetaryTotal?.AllowanceTotalAmount
                ? displayCurrency({
                      Amount: getTotalDiscountValue(invoice?.InvoiceLines || []),
                      Code: invoice.DocumentCurrencyCode,
                  })
                : displayCurrency({ Amount: 0, Code: invoice?.DocumentCurrencyCode || '' }),
        },
        {
            key: `${t('DOCUMENT_LEVEL_DISCOUNT')}:`,
            value: displayCurrency({
                Amount: getChargeDiscountAdditionValue(invoice?.AllowanceCharges || [], 'discount'),
                Code: invoice?.DocumentCurrencyCode || '',
            }),
        },
        {
            key: `${t('LINE_LEVEL_ALLOWNCE_CHARGE')}:`,
            value: displayCurrency({
                Amount: getTotalChargeValue(invoice?.InvoiceLines || []),
                Code: invoice?.DocumentCurrencyCode || '',
            }),
        },
        {
            key: `${t('DOCUMENT_LEVEL_ALLOWNCE_CHARGE')}:`,
            value: displayCurrency({
                Amount: getChargeDiscountAdditionValue(invoice?.AllowanceCharges || [], 'charge'),
                Code: invoice?.DocumentCurrencyCode || '',
            }),
        },
        {
            key: `${t('PAYABLE_AMOUNT')}:`,
            value: invoice?.LegalMonetaryTotal?.PayableAmount
                ? displayCurrency({
                      Amount: invoice?.LegalMonetaryTotal?.PayableAmount,
                      Code: invoice.DocumentCurrencyCode,
                  })
                : displayCurrency({ Amount: 0, Code: invoice?.DocumentCurrencyCode || '' }),
        },
    ];
    const invoiceInformation = [
        {
            key: `${t('IRN')}:`,
            value: invoice?.InvoiceId || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('INVOICE_ISSUE_DATE')}:`,
            value: UtcToLocalDate(invoice?.IssueDate) || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('INVOICE_ISSUE_TIME')}:`,
            value: invoice?.IssueTime || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('INVOICE_TYPE')}:`,
            value: getFixedInvoiceType(invoice?.InvoiceTypeCode).includes(EInvoiceTypeCode.B2B)
                ? t('STANDARD_TAX_INVOICE')
                : t('SIMPLIFIED_TAX_INVOICE') || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('INVOICE_TYPE_CODE')}:`,
            value: invoice?.InvoiceTypeCode || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('INVOICE_STATUS')}:`,
            value: invoiceStatus,
        },
        {
            key: `${t('ZATCA_STATUS')}:`,
            value: zatkaStatus,
        },
        {
            key: `${t('IS_PDF_GENERATED')}:`,
            value: invoice?.IsPdfGenerated ? t('YES') : t('NO') || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('ORDER_REFERENCE_ID')}:`,
            value: invoice?.OrderReferenceID || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CONTRACT_DOCUMENT_ID')}:`,
            value: invoice?.ContractDocumentID || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('TAX_TOTAL')}:`,
            value: displayCurrency({
                Code: invoice?.TaxCurrencyCode || '',
                Amount: invoice?.LegalMonetaryTotal?.TaxAmount || '',
            }),
        },
        {
            key: `${t('TAX_CURRENCY_CODE')}:`,
            value: invoice?.TaxCurrencyCode || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('DOCUMENT_CURRENCY_CODE')}:`,
            value: invoice?.DocumentCurrencyCode || DEFAULT_EMPTY_VALUE,
        },

        {
            key: `${t('FILE_NAME')}:`,
            value: invoice?.PdfFile?.FileName || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('TERMS_AND_CONDITIONS')}:`,
            value: invoice?.TermsAndConditions || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('LAST_UPDATED')}:`,
            value: UtcToLocalDate(invoice?.UpdatedAt) || DEFAULT_EMPTY_VALUE,
        },
        {
            key: `${t('CREATED')}:`,
            value: UtcToLocalDate(invoice?.CreatedAt) || DEFAULT_EMPTY_VALUE,
        },
    ];

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: t('INVOICE_INFORMATION'),
            children: <CoreList listData={invoiceInformation} />,
        },
        {
            key: '2',
            label: t('SUPPLIER_INFORMATION'),
            children: <SupplyInformation entity={entity} />,
        },
        {
            key: '3',
            label: t('CUSTOMER_INFORMATION'),
            children: <CustomerInformation />,
        },
        {
            key: '6',
            label: t('PAYMENT_MEANS'),
            children: <CoreList listData={paymentMeansTableData} />,
        },
        {
            key: '7',
            label: t('SUPPLY_DATE'),
            children: <CoreList listData={deliveryDateTableData} />,
        },
        {
            key: '8',
            label: t('LEGAL_MONETARY'),
            children: <CoreList listData={legalMonetaryDataTable} />,
        },
        {
            key: '9',
            label: t('INVOICE_LINES'),
            children: (
                <Table
                    rowKey={t('ID')}
                    columns={columns}
                    dataSource={invoiceLines}
                    pagination={false}
                />
            ),
        },
    ];

    return (
        <Spin spinning={isLoading}>
            <div className='container invoice-details '>
                <Row gutter={16}>
                    <Col span={2}>
                        <CoreBackButton text={''} />
                    </Col>
                    <Col span={22}>
                        <Row justify='space-between'>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={12}
                                xl={18}
                                style={{ marginBottom: 5 }}
                            >
                                <div className='invoice-id-wrapper'>
                                    <CoreTitle tag='h1' text={invoice?.InvoiceId || ''} />
                                </div>
                                <div>
                                    {t('INVOICE_STATUS')}: {invoiceStatus} | {t('ZATCA_STATUS')}:{' '}
                                    {zatkaStatus} | {t('CREATED_DATE')}:{' '}
                                    {UtcToLocalDate(invoice?.CreatedAt)} | {t('LAST_UPDATED')}:{' '}
                                    {UtcToLocalDate(invoice?.UpdatedAt)}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                                <div className='button-group'>
                                    {invoice && raiseInvoiceNote && raiseInvoiceNote.length > 0 && (
                                        <Dropdown menu={{ items: raiseInvoiceNote }}>
                                            <Button
                                                className='drop-down-button'
                                                icon={<DownOutlined />}
                                            >
                                                {t('RAISE_NOTE')}{' '}
                                                <img src={reportIconSVG} alt='report svg' />
                                            </Button>
                                        </Dropdown>
                                    )}
                                    <Dropdown menu={{ items: itemsViews }}>
                                        <Button
                                            loading={isViewing}
                                            className='drop-down-button'
                                            icon={<DownOutlined />}
                                        >
                                            {t('VIEW')}
                                        </Button>
                                    </Dropdown>

                                    <Dropdown menu={{ items: itemsDownloads }}>
                                        <Button
                                            loading={isDownloading}
                                            className='drop-down-button'
                                            icon={<DownOutlined />}
                                        >
                                            {t('DOWNLOAD')}
                                        </Button>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className='left-tab-wrapper invoice-details-tabs'>
                    <Tabs defaultActiveKey='1' items={tabItems} moreIcon={<ArrowRightOutlined />} />
                </div>
            </div>

            <XmlView
                xmlText={xmlText || ''}
                isOpenModal={isXmlModalOpen}
                closeModal={() => setXmlModalOpen(false)}
            />

            {pdfUrl && (
                <PdfView
                    url={pdfUrl}
                    isOpenModal={isPdfModalOpen}
                    closeModal={() => setPdfModalOpen(false)}
                />
            )}

            <JsonViewer
                jsonData={invoice?.ZatcaResponse}
                isOpenModal={isJsonModalOpen}
                closeModal={() => setJsonModalOpen(false)}
            />
        </Spin>
    );
};

export default InvoiceDetails;
