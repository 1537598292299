import { useState } from 'react';
import './CustomerStepper.scss';
import { Steps } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import { CustomerSelectFile, CustomerMapField, CustomerInvoiceSelect } from '../../index';
import { useDispatch } from 'react-redux';
import { setCustomerTitle } from '@store/slices/dynamicTitleSlice';

const steps = [
    {
        title: 'Configure',
    },
    {
        title: 'Map Fields',
    },
    {
        title: 'Preview',
    },
];

const CustomerStepper = () => {
    const [current, setCurrent] = useState(0);
    const dispatch = useDispatch();

    const onStepChange = (value: number) => {
        setCurrent(value);
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    if (current === 0) {
        dispatch(setCustomerTitle('Customers - Select file'));
    } else if (current === 1) {
        dispatch(setCustomerTitle('Map Fields'));
    } else if (current === 2) {
        dispatch(setCustomerTitle('Customers - Select File'));
    }

    return (
        <div className='customer-stepper-wrapper'>
            <div className='customer-stepper-inner-wrapper'>
                <div>
                    <div className='steps-wrapper'>
                        <Steps
                            current={current}
                            onChange={onStepChange}
                            size={'small'}
                            items={steps}
                        />
                    </div>
                    <div className='customer-stepper-content'>
                        {current === 0 && <CustomerSelectFile />}
                        {current === 1 && <CustomerMapField />}
                        {current === 2 && <CustomerInvoiceSelect />}
                    </div>
                </div>
                {current === 0 && (
                    <div className='customer-select-file-right'>
                        <div>
                            <CoreTitle tag='h5' text='Page Tips' />
                            <ul>
                                <li>
                                    Explore our Sample XLS File: Access a comprehensive guide to
                                    data field usage during the import process by downloading our
                                    sample XLS file..
                                </li>
                                <li>
                                    Format Conversion Made Easy: If your files are in different
                                    formats, effortlessly convert them to an accepted file format
                                    using a wide range of online or offline converters.
                                </li>
                                <li>
                                    Personalized Import Settings: Customize your import preferences
                                    and conveniently save them for future use.
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>

            <div className='customer-step-button-group'>
                {current < steps.length - 1 && <CoreButton onClick={() => prev()} text='Cancel' />}
                {current < steps.length - 1 && (
                    <CoreButton type='primary' onClick={() => next()} text='Next' />
                )}
            </div>
        </div>
    );
};

export default CustomerStepper;
