import { DeleteOutlined } from '@ant-design/icons';
import { DiscountType } from './DiscountAmountLine';
import { IInvoiceItemCharges, IInvoiceLinesItem } from '@models/invoice.model';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store/hooks';
import { removeInvoiceLineCharge } from '@store/slices/einvoiceSlice';

interface DiscountDisplayProps {
    invoiceLine: IInvoiceLinesItem | undefined;
    discountType: DiscountType;
}

type ModifyIInvoiceItemCharges = IInvoiceItemCharges & { Index: number };

export default function DiscountDisplay({ invoiceLine, discountType }: DiscountDisplayProps) {
    const { t } = useTranslation();
    const charges = invoiceLine?.AllowanceCharges || [];
    const dispatch = useAppDispatch();

    const allowanceCharges: ModifyIInvoiceItemCharges[] = charges.reduce(
        (acc: ModifyIInvoiceItemCharges[], item, index) => {
            if (discountType === 'charge' && item.ChargeIndicator === true) {
                acc.push({ ...item, Index: index } as ModifyIInvoiceItemCharges);
            }
            if (discountType === 'discount' && item.ChargeIndicator === false) {
                acc.push({ ...item, Index: index } as ModifyIInvoiceItemCharges);
            }
            return acc;
        },
        [],
    );

    const onRemoveCharge = (index: number) => {
        dispatch(
            removeInvoiceLineCharge({
                LineChargeIndex: index,
                ItemId: invoiceLine?.SerialId || '',
            }),
        );
    };

    const columns: ColumnsType<ModifyIInvoiceItemCharges> = [
        {
            title: t('Amount'),
            dataIndex: 'Amount',
        },
        {
            title: t('Reason'),
            dataIndex: 'Reason',
        },
        {
            title: t('Action'),
            dataIndex: 'Amount',
            render: (value: string, record) => (
                <>
                    <div
                        className='delete-table-icon'
                        onClick={(e) => onRemoveCharge(record.Index)}
                        aria-hidden='true'
                    >
                        <DeleteOutlined />
                    </div>
                </>
            ),
        },
    ];
    return (
        <div>
            <Table
                columns={columns}
                dataSource={allowanceCharges}
                rowKey={'Index'}
                rowClassName='cursor-pointer'
                pagination={false}
            />
        </div>
    );
}
