import { useState } from 'react';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { CoreLogo, CoreIconButton } from '@packages';
import './LayoutWrapper.scss';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import MenuItems from '@components/main-layout/menu-items/MenuItems';
const { Sider, Content } = Layout;

export const LayoutWrapper = () => {
    const navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className='main-layout-wrapper'>
            <Layout>
                <Sider theme='light' width={280} trigger={null} collapsible collapsed={collapsed}>
                    <div
                        className='logo-wrapper'
                        onClick={() => navigate('/dashboard')}
                        aria-hidden='true'
                    >
                        <CoreLogo size={'medium'} isWithName={!collapsed} />
                    </div>
                    <div className='line-wrapper'>
                        <div className='line' />
                    </div>
                    <div
                        className='collapsed-button'
                        onClick={() => setCollapsed(!collapsed)}
                        aria-hidden='true'
                    >
                        <CoreIconButton
                            variant='round'
                            icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
                        />
                    </div>
                    <div>
                        <MenuItems />
                    </div>
                </Sider>
                <Layout className='site-layout'>
                    <NavigationBar />
                    <Content className='layout-content'>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default LayoutWrapper;
