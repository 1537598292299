import React, { useState, useEffect } from 'react';
import { Modal, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { CoreStatus } from '@packages';
import { useNavigate } from 'react-router-dom';
import './OrganizationListTable.scss';
import InviteClient from '@components/invite-client/InviteClient';
import { IOrganization } from '@models/organization.model';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { DEFAULT_TABLE_SIZE } from '@config/constants';
import { useTranslation } from 'react-i18next';

interface IOrganizationTableData {
    key: React.Key;
    OrganizationId: string;
    LegalRegistrationName: string;
    LegalRegistrationNameEn: string;
    VATRegistrationNumber: string;
    CompanyRegistrationNumber: string;
    CreatedAt: string;
    IsActive: boolean;
    IsCompleted: boolean;
}

const OrganizationListTable = () => {
    const { t } = useTranslation();
    const [orgList, setOrgList] = useState<IOrganizationTableData[]>([]);
    const { isLoading, organizations } = useSelector((state: RootState) => state.organization);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const formatOrganizationListData = () => {
        const data: IOrganizationTableData[] = [];
        organizations.forEach((organization: IOrganization) => {
            data.push({
                key: organization.OrganizationId || '',
                OrganizationId: organization.OrganizationId,
                LegalRegistrationName: organization.LegalRegistrationName,
                LegalRegistrationNameEn: organization.LegalRegistrationNameEn,
                VATRegistrationNumber: organization.VatRegistrationNumber,
                CompanyRegistrationNumber: organization.CompanyRegistrationNumber,
                CreatedAt: organization?.CreatedAt || '',
                IsActive: organization?.IsActive,
                IsCompleted: organization?.Workflow?.Completed,
            });
        });
        setOrgList(data);
    };

    useEffect(() => {
        formatOrganizationListData();
    }, [organizations]);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = (e: any) => {
        e.stopPropagation();
        setIsModalOpen(true);
    };

    const navigate = useNavigate();

    const goToOrganizationUpdate = (e: any, organizationId: string) => {
        e.stopPropagation();
        navigate(`/organizations/update/${organizationId}`);
    };

    const goToOrganizationStepper = (e: any, organizationId: string) => {
        e.stopPropagation();
        navigate(`/organization-setup/${organizationId}/organization-information`);
    };

    const columns: ColumnsType<IOrganizationTableData> = [
        {
            title: t('LEGAL_REGISTRATION_NAME'),
            dataIndex: ['LegalRegistrationName', 'LegalRegistrationNameEn'],
            render: (text, data) => (
                <Tooltip title={data.LegalRegistrationNameEn}>
                    <span>{data.LegalRegistrationName}</span>
                </Tooltip>
            ),
        },
        {
            title: t('VAT_REGISTRATION_NUMBER'),
            dataIndex: 'VATRegistrationNumber',
        },
        {
            title: t('COMPANY_REGISTRATION_NUMBER'),
            dataIndex: 'CompanyRegistrationNumber',
        },
        {
            title: t('CREATED_DATE'),
            dataIndex: 'CreatedAt',
            render: (text: string) => <span>{UtcToLocalDate(text)}</span>,
        },
        {
            title: t('ACTIVE_STATUS'),
            dataIndex: 'IsActive',
            render: (IsActive: string) => (
                <CoreStatus
                    type={IsActive ? 'success' : 'inactive'}
                    status={IsActive ? t('ACTIVE') : t('INACTIVE')}
                />
            ),
        },
        {
            title: t('COMPLETE_STATUS'),
            dataIndex: 'IsCompleted',
            render: (IsCompleted: boolean) => (
                <CoreStatus
                    type={IsCompleted ? 'success' : 'inactive'}
                    status={IsCompleted ? t('COMPLETE') : t('INCOMPLETE')}
                />
            ),
        },
        {
            title: '',
            dataIndex: 'OrganizationId',
            render: (organizationId: string, data: IOrganizationTableData) => (
                <div className='action-buttons'>
                    {/*<div className='invite-client-icon' onClick={(e) => showModal(e)}>*/}
                    {/*    <UserAddOutlined />*/}
                    {/*</div>*/}
                    <div
                        className='edit-table-icon'
                        onClick={(e) =>
                            data.IsCompleted
                                ? goToOrganizationUpdate(e, organizationId)
                                : goToOrganizationStepper(e, organizationId)
                        }
                    >
                        <EditOutlined />
                    </div>
                </div>
            ),
        },
    ];

    const handleRowClick = (record: IOrganizationTableData) => {
        const organizationWorkflow = organizations.find(
            (data) => data.OrganizationId === record.OrganizationId,
        )?.Workflow;
        if (organizationWorkflow?.Completed) {
            navigate(`/organizations/${record.OrganizationId}`);
        } else {
            if (organizationWorkflow?.CurrentIndex === 0) {
                navigate(`/organization-setup/${record.OrganizationId}/organization-information`);
            }
            if (organizationWorkflow?.CurrentIndex === 1) {
                navigate(`/organization-setup/${record.OrganizationId}/entity`);
            }
            if (organizationWorkflow?.CurrentIndex === 2) {
                navigate(`/organization-setup/${record.OrganizationId}/invoice-phase`);
            }
            if (organizationWorkflow?.CurrentIndex === 3) {
                navigate(`/organization-setup/${record.OrganizationId}/device`);
            }
        }
    };

    return (
        <div className='ksa-table-wrapper table-auto-scroll-without-header'>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={orgList}
                rowClassName='cursor-pointer'
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowClick(record), // click row
                    };
                }}
                scroll={{ x: 600 }}
                pagination={{
                    pageSize: DEFAULT_TABLE_SIZE,
                    total: orgList?.length ?? 0,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t('OF')} ${total} ${t('ITEMS')}`,
                }}
            />
            {/*<Modal*/}
            {/*    centered*/}
            {/*    open={isModalOpen}*/}
            {/*    onOk={handleOk}*/}
            {/*    onCancel={handleCancel}*/}
            {/*    width={460}*/}
            {/*    footer={null}*/}
            {/*    closable={false}*/}
            {/*>*/}
            {/*    <InviteClient handleOk={handleOk} handleCancel={handleCancel} />*/}
            {/*</Modal>*/}
        </div>
    );
};

export default OrganizationListTable;
