import { getOrganizations } from '@store/slices/organizationSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import './Authorization.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { checkRolePermission } from '@utils/checkRolePermission';

const Authorization = () => {
    const { Roles } = useSelector((state: RootState) => state.user.userDetails);
    const isAdmin = checkRolePermission(Roles, ['admin']);
    useEffect(() => {
        authorization();
    }, []);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isPageLoading, setLoading] = useState<boolean>(true);

    const authorization = async () => {
        if (isAdmin) {
            navigate('/dashboard', { replace: true });
            return;
        }
        await dispatch(getOrganizations())
            .then(unwrapResult)
            .then((organizations) => {
                if (organizations?.length === 0 || !organizations) {
                    navigate('/organization-initiate');
                } else if (organizations.length === 1 && !organizations[0]?.Workflow?.Completed) {
                    if (organizations[0]?.Workflow?.CurrentIndex === 0) {
                        navigate(
                            `/organization-setup/${organizations[0].OrganizationId}/organization-information`,
                        );
                    }
                    if (organizations[0]?.Workflow?.CurrentIndex === 1) {
                        navigate(`/organization-setup/${organizations[0].OrganizationId}/entity`);
                    }
                    if (organizations[0]?.Workflow?.CurrentIndex === 2) {
                        navigate(
                            `/organization-setup/${organizations[0].OrganizationId}/invoice-phase`,
                        );
                    }
                    if (organizations[0]?.Workflow?.CurrentIndex === 3) {
                        navigate(`/organization-setup/${organizations[0].OrganizationId}/device`);
                    }
                } else {
                    navigate('/dashboard', { replace: true });
                }
            });
    };

    return (
        <div className='authorization-wrapper'>
            <Spin size='large' spinning={isPageLoading} delay={500} />
        </div>
    );
};

export default Authorization;
