import "./SubscribeSuccess.scss"
import { CheckCircleOutlined } from "@ant-design/icons";
import { CoreButton, CoreTitle } from "@packages";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";

const SubscribeSuccess = () => {
    const navigate = useNavigate()

    return (
        <div className="subscribe-success-wrapper">
            <div className="subscribe-success-inner-wrapper">
                <CheckCircleOutlined className='checked-icon' />
                <CoreTitle tag="h2" text="Thanks for subscribing" />
                <p className="success-subtitle">A payment to www.hashsolution.com will appear on your statement</p>
                <div className="company-details">
                    <p className="company-name">www.hashsolution.com</p>
                    <p className="payment-amount">SAR 3,240.00</p>
                </div>
                <div className="subscribe-success-footer">
                    <p className="powered-by">Powered by <span>Stripe</span></p>
                    <Divider type="vertical" className='footer-divider' />
                    <p className="footer-links">Terms</p>
                    <p className="footer-links">Privacy</p>
                </div>

                <CoreButton type="primary" text="Go to home" onClick={() => {
                    navigate('/')
                }} />

            </div>
        </div>
    )
}

export default SubscribeSuccess