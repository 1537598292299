import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrganizationAPI } from '@service/organization.service';
import { IEntity, IEntityIdPayload, IOrganization } from '@models/organization.model';
import { RootState } from '@store/store';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { RequestStatus } from '@models/async-status.enum';

interface IOrganizationState {
    isLoading: boolean;
    openFilter: boolean;
    orgFilters: IOrgFilters;
    organization: IOrganization | null;
    organizations: IOrganization[];
    entities: IEntity[];
    entity: IEntity | null;
    availableOrganizations: {
        status: RequestStatus;
        data: IOrganization[];
    };
}

interface IOrgFilters {
    legalRegistrationName: string;
    vatRegistrationNumber: string;
    organizationLegacyId: string;
    organizationRegistrationScheme: string;
    activeStatus: string;
    dateRange: string;
}

const initialState: IOrganizationState = {
    openFilter: false,
    isLoading: true,
    orgFilters: {
        legalRegistrationName: '',
        vatRegistrationNumber: '',
        organizationLegacyId: '',
        organizationRegistrationScheme: '',
        activeStatus: '',
        dateRange: '',
    },
    organization: null,
    organizations: [],
    entities: [],
    entity: null,
    availableOrganizations: {
        status: 'idle',
        data: [],
    },
};

export const getOrganizations = createAsyncThunk('getOrganizations', async () => {
    try {
        const response = await OrganizationAPI.getOrganizations();
        return response?.Result || [];
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});

export const getAvailableOrganizations = createAsyncThunk('getAvailableOrganizations', async () => {
    try {
        const response = await OrganizationAPI.getAvailableOrganizations();
        return response;
    } catch (error: any) {
        console.error(error);
        throw error?.response?.Message || error?.message;
    }
});

export const getOrganization = createAsyncThunk(
    'getOrganization',
    async (organizationId: string) => {
        try {
            const response = await OrganizationAPI.getOrganization(organizationId);
            return response.Result;
        } catch (error: any) {
            return error?.response?.Message || error?.message;
        }
    },
);

export const getEntities = createAsyncThunk(
    'organization/entities',
    async (organizationId: string) => {
        try {
            const response = await OrganizationAPI.getEntities(organizationId);
            return response.Result;
        } catch (error: any) {
            console.log(error);
            throw error?.response?.Message || error?.message;
        }
    },
);

export const getEntity = createAsyncThunk('organization/entity', async (entityId: string) => {
    try {
        const response = await OrganizationAPI.getEntity(entityId);
        return response.Result;
    } catch (error: any) {
        console.log(error);
        return error?.response?.Message || error?.message;
    }
});

export const enableEntity = createAsyncThunk(
    'organization/enable-entity',
    async (payload: IEntityIdPayload) => {
        try {
            const response = await OrganizationAPI.enableEntity(payload);
            return response.Result;
        } catch (error: any) {
            throw error?.response?.Message || error?.message;
        }
    },
);
export const disableEntity = createAsyncThunk(
    'organization/disable-entity',
    async (payload: IEntityIdPayload) => {
        try {
            const response = await OrganizationAPI.disableEntity(payload);
            return response.Result;
        } catch (error: any) {
            throw error?.response?.Message || error?.message;
        }
    },
);

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        toggleFilter: (state) => {
            state.openFilter = !state.openFilter;
        },
        setFilters: (state: any, action) => {
            state.orgFilters = action.payload;
        },
        removeFilters: (state, action) => {
            const key: keyof IOrgFilters = action.payload;
            state.orgFilters[key] = '';
        },
        clearAll: (state) => {
            state.orgFilters = initialState.orgFilters;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrganizations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOrganizations.fulfilled, (state, action) => {
            state.isLoading = false;
            state.organizations = action.payload;
        });
        builder.addCase(getOrganizations.rejected, (state, action) => {
            state.isLoading = false;
            state.organizations = [];
        });
        builder.addCase(getOrganization.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOrganization.fulfilled, (state, action) => {
            state.isLoading = false;
            state.organization = action.payload;
        });
        builder.addCase(getOrganization.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getEntities.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEntities.fulfilled, (state, action) => {
            state.isLoading = false;
            state.entities = action.payload || [];
        });
        builder.addCase(getEntities.rejected, (state, action) => {
            state.isLoading = false;
            state.entities = [];
        });
        builder.addCase(getEntity.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEntity.fulfilled, (state, action) => {
            state.isLoading = false;
            state.entity = action.payload;
        });
        builder.addCase(getEntity.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getAvailableOrganizations.pending, (state) => {
            state.availableOrganizations.status = 'loading';
        });
        builder.addCase(getAvailableOrganizations.fulfilled, (state, action) => {
            state.availableOrganizations.status = 'idle';
            state.availableOrganizations.data = action.payload || [];
        });
        builder.addCase(getAvailableOrganizations.rejected, (state) => {
            state.availableOrganizations.status = 'failed';
            state.availableOrganizations.data = [];
        });
    },
});

export const { toggleFilter, setFilters, removeFilters, clearAll } = organizationSlice.actions;
export const selectAvailableOrganizationsInfo = (state: RootState) => ({
    ...state.organization.availableOrganizations,
    data: state.organization.availableOrganizations.data.map((organization) => ({
        ...organization,
        value: organization?.OrganizationId,
        label: organization?.LegalRegistrationName || DEFAULT_EMPTY_VALUE,
        hoverName: organization?.LegalRegistrationNameEn,
    })),
});

export default organizationSlice.reducer;
