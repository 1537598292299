import './Subscribe.scss';
import { Tabs } from 'antd';
import Subscription from '../../components/subscription/Subscription';
import Payment from '../../components/payment/Payment';
import { CoreTitle } from '@packages';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';
import { useTranslation } from 'react-i18next';

type tabsType = 'subscription' | 'payment';

export default function Subscribe() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { state }: { state?: { currentTab: tabsType } } = useLocation();
    const [activeTabsKey, setActiveTabsKey] = useState<tabsType>(
        state?.currentTab || 'subscription',
    );

    const tabItems = [
        {
            label: t('SUBSCRIPTION'),
            key: 'subscription',
            children: <Subscription />,
        },
        {
            label: t('PAYMENT'),
            key: 'payment',
            children: <Payment />,
        },
    ];

    useEffect(() => {
        dispatch(getPaymentMethods());
    }, []);

    return (
        <div className='subscribe-wrapper'>
            <CoreTitle tag='h4' text={t("YOUR_SUBSCRPTION")} />
            <div className='subscribe-inner-wrapper'>
                <Tabs
                    activeKey={activeTabsKey}
                    onChange={(value) => {
                        setActiveTabsKey(value as tabsType);
                    }}
                    items={tabItems}
                />
            </div>
        </div>
    );
}
