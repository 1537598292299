import React from 'react';
import { CoreButton, CoreLogo } from '@packages';
import './OrganizationSetupLayoutWrapper.scss';
import { logOutUser } from '@store/slices/authSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useNavigate } from 'react-router-dom';
import { getOrganizations } from '@store/slices/organizationSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { selectAppLanguage } from '@store/slices/languageSlice';
import { useLanguage } from '@hooks';
import LanguageMenu from '@components/main-layout/layout-wrapper/LanguageMenu';

interface IOrganizationSetupProps {
    children: React.ReactNode;
}

const OrganizationSetupLayoutWrapper = (props: IOrganizationSetupProps) => {
    const { t } = useTranslation();
    const { children } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const logOutPortal = async () => {
        await dispatch(logOutUser());
    };

    const logoClicked = async () => {
        await dispatch(getOrganizations())
            .then(unwrapResult)
            .then((organizations) => {
                if (organizations?.length >= 1 && organizations[0]?.Workflow?.Completed) {
                    navigate('/dashboard');
                }
            });
    };

    return (
        <div className='organization-setup-layout-wrapper'>
            <div className='header'>
                <div onClick={logoClicked}>
                    <CoreLogo size='medium' isWithName={true} />
                </div>

                <div className='button-area'>
                    <LanguageMenu />
                    <CoreButton onClick={logOutPortal} text={t('LOG_OUT')} type='basic' />
                </div>
            </div>
            <div>{children}</div>
        </div>
    );
};

export default OrganizationSetupLayoutWrapper;
