import './PricingPlans.scss';
import { CoreTitle } from '@packages';
import { Tabs } from 'antd';
import PriceCard from '../../components/annual-price/AnnualPrice';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const PricingPlans = () => {
    const { t } = useTranslation();
    const tabItems = [
        {
            label: t('MONTHLY_PRICE'),
            key: '1',
            children: <PriceCard type='month' />,
        },
        {
            label: t('ANNUAL_PRICE'),
            key: '2',
            children: <PriceCard type='year' />,
        },
    ];
    return (
        <div className='pricing-plans-wrapper'>
            <div className='pricing-plans-inner-wrapper'>
                <CoreTitle tag='h2' text={t('PRICING_PLAN')} />
                <p>{t('TRY_OUR_BASIC_PLANRISK_FREE_FOR_30_DAYS')}</p>
                <Tabs items={tabItems} centered={true} />
            </div>
        </div>
    );
};

export default PricingPlans;