import { CoreFileUploadDragger } from '@packages';
import './CustomerSelectFile.scss';
import { Col, Radio, Row, Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';

interface ICustomerSelectFile {
    uploadFile: string;
    duplicateHandling: boolean;
}

const CustomerSelectFile = () => {
    const options = [
        {
            value: '1',
            label: 'UTF-8 (Unicode)',
        },
        {
            value: '2',
            label: 'UTF-18 (Unicode)',
        },
    ];

    const {
        control,
        handleSubmit,
    } = useForm<ICustomerSelectFile>({ mode: 'onChange' });
    const onSubmit = (data: ICustomerSelectFile) => {
        console.log(data);
    };

    return (
        <div className='customer-select-file-wrapper'>
            <div className='customer-select-file-left'>
                <p className='download-message'>
                    Ensure a Seamless Import: Download a{' '}
                    <span className='download-link'>sample CSV</span> or
                    <span className='download-link'>XLS file</span> and perform a side-by-side
                    comparison with your import file to ensure its flawless suitability for the
                    import process.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={100} className='margin-bottom-30'>
                        <Col span={8} className='customer-col'>
                            <label className='customer-label'>Upload file</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => <CoreFileUploadDragger />}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={100} className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Duplicate Handling</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => (
                                    <div>
                                        <Radio className='customer-radio' checked={true}>
                                            Skip
                                        </Radio>
                                        <p className='customer-radio-sub-title'>
                                            Retains the existing records and dose not sync the
                                            identical records from the import file.
                                        </p>
                                    </div>
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={100} align='middle' className='margin-bottom-30'>
                        <Col span={8}></Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => (
                                    <div>
                                        <Radio className='customer-radio'>Overwrite</Radio>
                                        <p className='customer-radio-sub-title'>
                                            Overwrites existing records with the identical records
                                            in the import file.
                                        </p>
                                    </div>
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={100} align='middle' className='margin-bottom-30'>
                        <Col span={8}>
                            <label className='customer-label'>Character Encoding</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='uploadFile'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='UTF-8 (Unicode)'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
};

export default CustomerSelectFile;
