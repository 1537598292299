/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './CardView.scss';
import { MoreOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export interface ICardViewProps {
    type: string;
    number: string;
    expiry: string;
    status?: string;
    onClick: () => void;
    selectedCardId?: string;
    cardId: string;
}

export default function CardView({
    type,
    number,
    expiry,
    status,
    onClick,
    selectedCardId,
    cardId,
}: ICardViewProps) {
    return (
        <div
            className='credit-card'
            onClick={onClick}
            style={
                (selectedCardId && selectedCardId === cardId && { border: '2px solid #101828' }) ||
                {}
            }
        >
            <div className='card-header'>
                <div className='card-type'>{type}</div>
                <div className='card-options'>
                    <MoreOutlined />
                </div>
            </div>
            <div className='card-body'>
                <div className='card-number'>•••• •••• •••• {number}</div>
                <div className='card-bottom-container'>
                    <div className='card-expiry'>Expires: {expiry}</div>
                    {status && (
                        <div className='card-status'>
                            <Tag color='blue'>{status}</Tag>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
