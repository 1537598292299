import { CoreTitle } from '@packages';
import './CustomerMapField.scss';
import { Checkbox, Col, Row, Select } from 'antd';
import { useForm, Controller } from 'react-hook-form';

interface ICustomerMapField {
    displayName: string;
    displayNameSecondary: string;
    customerSubType: string;
    buyerIDLabel: string;
    buyerIDValue: string;
    paymentTerms: string;
    paymentTermsLabel: string;
    currencyCode: string;
    companyName: string;
    salutation: string;
    firstName: string;
    lastName: string;
    emaillD: string;
    phone: string;
    mobilePhone: string;
    facebook: string;
    twitter: string;
    department: string;
    designation: string;
    creditLimit: string;
    skypeIdentity: string;
    notes: string;
    website: string;
    websitewebsite: string;
    billingAttention: string;
    billingAddress: string;
    billingStreet2: string;
    billingAdditionalNumber: string;
    billingCity: string;
    billingDistrict: string;
    billingState: string;
    billingCountry: string;
    billingCode: string;
    billingPhone: string;
    billingFax: string;
    mapFields: string;
    billingAttentionSecondary: string;
    billingAddressSecondary: string;
    billingStreet2Secondary: string;
    billingCitySecondary: string;
    billingDistrictSecondary: string;
    billingStateSecondary: string;
    billingCountrySecondary: string;
    billingCodeSecondary: string;
    billingPhoneSecondary: string;
    billingFaxSecondary: string;
    shippingAddress: string;
    BillingAdditionalNumberSecondary: string;
    shippingAdditionalNumber: string;
    shippingCity: string;
    shippingStreet2: string;
    shippingAttention: string;
    shippingDistrict: string;
    shippingFax: string;
    shippingPhone: string;
    shippingState: string;
    shippingCountry: string;
    shippingCode: string;
    shippingAttentionSecondary: string;
    Status: string;
    ownerName: string;
    openingBalanceExchange: string;
    shippingAddressSecondary: string;
    shippingStreet2Secondary: string;
    shippingAdditionalNumberSecondary: string;
    shippinCitySecondary: string;
    shippingDistrictSecondary: string;
    shippingCodeSecondary: string;
    shippingPhoneSecondary: string;
    shippingFaxSecondary: string;
    openingBalance: string;
    bankAccountPayment: string;
    priceList: string;
    saveTheseSelections: string;
}

const CustomerMapField = () => {
    const options = [
        {
            value: '1',
            label: 'Mr.',
        },
        {
            value: '2',
            label: 'Ms.',
        },
        {
            value: '3',
            label: 'Mrs.',
        },
    ];

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ICustomerMapField>({ mode: 'onChange' });
    const onSubmit = (data: ICustomerMapField) => {
        console.log(data);
    };

    return (
        <div className='customer-map-field-wrapper'>
            <div className='selected-device'>
                <p>
                    Your Selected File :{' '}
                    <span className='file-name'>Samsung R&D - batch 3.csv</span>
                </p>
                <p>The best match to each field on the selected file have been auto-selected.</p>
            </div>
            <CoreTitle tag='h5' text='Contact Details' />
            <div className='customer-map-field-form'>
                <Row gutter={130} align='middle' className='margin-bottom-16'>
                    <Col span={8}>
                        <p>Predefined Data Headers</p>
                    </Col>
                    <Col span={16}>
                        <p>Imported file headers</p>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Display Name</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='displayName'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.displayName?.type === 'required' && (
                                <span className='error-message'>
                                    Display Name Type field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Display Name <br /> (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='displayNameSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.displayNameSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Display Name Secondary field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Customer Sub Type</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='customerSubType'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.customerSubType?.type === 'required' && (
                                <span className='error-message'>
                                    Customer Sub Type field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Buyer ID Label</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='buyerIDLabel'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.buyerIDLabel?.type === 'required' && (
                                <span className='error-message'>
                                    Buyer ID Label field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Buyer ID Value</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='buyerIDValue'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.buyerIDValue?.type === 'required' && (
                                <span className='error-message'>
                                    Buyer ID Value field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Payment Terms</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='paymentTerms'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.paymentTerms?.type === 'required' && (
                                <span className='error-message'>
                                    Payment Terms field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Payment Terms Label</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='paymentTermsLabel'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.paymentTermsLabel?.type === 'required' && (
                                <span className='error-message'>
                                    Payment Terms Label field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Currency Code</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='currencyCode'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.currencyCode?.type === 'required' && (
                                <span className='error-message'>
                                    Currency Code field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Company Name</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='companyName'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.companyName?.type === 'required' && (
                                <span className='error-message'>
                                    Company Name field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Salutation</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='salutation'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.salutation?.type === 'required' && (
                                <span className='error-message'>Salutation field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>First Name</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='firstName'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.firstName?.type === 'required' && (
                                <span className='error-message'>First Name field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Last Name</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='lastName'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.lastName?.type === 'required' && (
                                <span className='error-message'>Last Name field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Email lD</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='emaillD'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.emaillD?.type === 'required' && (
                                <span className='error-message'>Email lD field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Phone</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='phone'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.phone?.type === 'required' && (
                                <span className='error-message'>Phone field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Mobile Phone</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='mobilePhone'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.mobilePhone?.type === 'required' && (
                                <span className='error-message'>
                                    Mobile Phone field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Facebook</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='facebook'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.facebook?.type === 'required' && (
                                <span className='error-message'>Facebook field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Twitter</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='twitter'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.twitter?.type === 'required' && (
                                <span className='error-message'>Twitter field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Department</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='department'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.department?.type === 'required' && (
                                <span className='error-message'>Department field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Designation</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='designation'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.designation?.type === 'required' && (
                                <span className='error-message'>Designation field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Credit Limit</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='creditLimit'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.creditLimit?.type === 'required' && (
                                <span className='error-message'>
                                    Credit Limit field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Skype Identity</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='skypeIdentity'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.skypeIdentity?.type === 'required' && (
                                <span className='error-message'>
                                    Skype Identity field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Notes</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='notes'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.notes?.type === 'required' && (
                                <span className='error-message'>Notes field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Website</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='website'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.website?.type === 'required' && (
                                <span className='error-message'>Website field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Attention</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingAttention'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingAttention?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Attention field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Address</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingAddress'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingAddress?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Address field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Street 2</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingStreet2'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingStreet2?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Street 2 field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Additional Number</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingAdditionalNumber'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingAdditionalNumber?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Additional Number field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing City</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingCity'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingCity?.type === 'required' && (
                                <span className='error-message'>
                                    Billing City field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing District</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingDistrict'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingDistrict?.type === 'required' && (
                                <span className='error-message'>
                                    Billing District field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing State</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingState'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingState?.type === 'required' && (
                                <span className='error-message'>
                                    Billing State field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Country</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingCountry'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingCountry?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Country field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Code</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingCode'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingCode?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Code field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Phone</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingPhone'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingPhone?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Phone field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Billing Fax</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingFax'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingFax?.type === 'required' && (
                                <span className='error-message'>Billing Fax field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Map Fields</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='mapFields'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.mapFields?.type === 'required' && (
                                <span className='error-message'>Map Fields field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Attention
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingAttentionSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingAttentionSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Attention Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Address
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingAddressSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingAddressSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Address (Secondary Language) field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Street 2 (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingStreet2Secondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingStreet2Secondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Street 2 (Secondary Language) field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Additional Number
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='BillingAdditionalNumberSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.BillingAdditionalNumberSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Additional Number Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing City
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingCitySecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingCitySecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing City Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>{' '}
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing District
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingDistrictSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingDistrictSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing District Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing State
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingStateSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingStateSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing State Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Country
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingCountrySecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingCountrySecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Country Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Code
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingCodeSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingCodeSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Code Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Phone
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingPhoneSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingPhoneSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Phone Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Billing Fax
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='billingFaxSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.billingFaxSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Billing Fax Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Attention</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingAttention'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingAttention?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Attention field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Address</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingAddress'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingAddress?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Address field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Street 2</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingStreet2'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingStreet2?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Street 2 field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Map Fields</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='mapFields'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.mapFields?.type === 'required' && (
                                <span className='error-message'>Map Fields field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Additional Number</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingAdditionalNumber'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingAdditionalNumber?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Additional Number field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping City</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingCity'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingCity?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping City field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping District</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingDistrict'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingDistrict?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping District field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping State</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingState'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingState?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping State field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Country</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingCountry'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingCountry?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Country field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Code</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingCode'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingCode?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Code field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Fax</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingFax'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingFax?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Fax field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Shipping Phone</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingPhone'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingPhone?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Phone field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping Attention
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingAttentionSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingAttentionSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Attention Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping Address
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingAddressSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingAddressSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Address Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping Street 2<br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingStreet2Secondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingStreet2Secondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Street 2 Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping Additional Number
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingAdditionalNumberSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingAdditionalNumberSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Additional Number Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping City
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippinCitySecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippinCitySecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping City Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping District
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingDistrictSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingDistrictSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping District Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping Code
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingCodeSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingCodeSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Code Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping Phone
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingPhoneSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingPhoneSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Phone Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>
                                Shipping Fax
                                <br />
                                (Secondary Language)
                            </label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='shippingFaxSecondary'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.shippingFaxSecondary?.type === 'required' && (
                                <span className='error-message'>
                                    Shipping Fax Secondary Language field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Owner Name</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='ownerName'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.ownerName?.type === 'required' && (
                                <span className='error-message'>Owner Name field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Opening Balance</label>
                        </Col>
                        <Col span={8} className='padding-right-10'>
                            <Controller
                                name='openingBalance'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.openingBalance?.type === 'required' && (
                                <span className='error-message'>
                                    Opening Balance field is required
                                </span>
                            )}
                        </Col>
                        <Col span={8} className='padding-left-10'>
                            <Controller
                                name='openingBalanceExchange'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='1234567.89'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.openingBalanceExchange?.type === 'required' && (
                                <span className='error-message'>
                                    Opening Balance Exchange Rate field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Opening Balance Exchange Rate</label>
                        </Col>
                        <Col span={8} className='padding-right-10'>
                            <Controller
                                name='openingBalanceExchange'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.openingBalanceExchange?.type === 'required' && (
                                <span className='error-message'>
                                    Opening Balance Exchange Rate field is required
                                </span>
                            )}
                        </Col>
                        <Col span={8} className='padding-left-10'>
                            <Controller
                                name='openingBalanceExchange'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='1234567.89'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.openingBalanceExchange?.type === 'required' && (
                                <span className='error-message'>
                                    Opening Balance Exchange Rate field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Status</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='Status'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.Status?.type === 'required' && (
                                <span className='error-message'>Status field is required</span>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={130} align='middle' className='margin-bottom-20'>
                        <Col span={8}>
                            <label className='customer-label'>Bank Account Payment</label>
                        </Col>
                        <Col span={16}>
                            <Controller
                                name='bankAccountPayment'
                                render={({ field }) => (
                                    <Select
                                        className='customer-select'
                                        showSearch
                                        placeholder='Select'
                                        options={options}
                                    />
                                )}
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.bankAccountPayment?.type === 'required' && (
                                <span className='error-message'>
                                    Bank Account Payment field is required
                                </span>
                            )}
                        </Col>
                    </Row>
                </form>
            </div>
            <div className='margin-top-40'>
                <CoreTitle tag='h5' text='Item Details' />
                <div className='customer-map-field-form'>
                    <Row gutter={130} align='middle' className='margin-bottom-6'>
                        <Col span={8}>
                            <p>Zoho Books Field</p>
                        </Col>
                        <Col span={16}>
                            <p>Imported file headers</p>
                        </Col>
                    </Row>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={130} align='middle' className='margin-bottom-20'>
                            <Col span={8}>
                                <label className='customer-label'>Price List</label>
                            </Col>
                            <Col span={16}>
                                <Controller
                                    name='priceList'
                                    render={({ field }) => (
                                        <Select
                                            className='customer-select'
                                            showSearch
                                            placeholder='Select'
                                            options={options}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.priceList?.type === 'required' && (
                                    <span className='error-message'>
                                        Price List field is required
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </form>
                    <div className='dropdown-list-item'>
                        <Row align='middle' gutter={130} className='margin-bottom-30'>
                            <Col span={24}>
                                <Controller
                                    name='saveTheseSelections'
                                    render={({ field }) => (
                                        <Checkbox className='general-checkbox'>
                                            Save these selections for use during future imports.
                                        </Checkbox>
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.saveTheseSelections?.type === 'required' && (
                                    <span className='error-message'>
                                        Save these selections for use during future imports.
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerMapField;
