import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import {
    IInvoiceCreation,
    IInvoiceParams,
    IInvoicePayload,
    IInvoiceResult,
} from '@models/invoice.model';
import { AxiosError } from 'axios';
import { processQuery } from '@utils/processQuery';

export const INVOICE_API = {
    createInvoice: async (payload: IInvoiceCreation): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/Create`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                ...payload,
            });
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getAllInvoices: async (payload: IInvoicePayload): Promise<IInvoiceResult> => {
        const url = APP_CONFIG.eisUrl + `InvoiceCommand/GetInvoices`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
    getInvoice: async (invoiceId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `InvoiceQuery/GetInvoice${processQuery({ invoiceId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
