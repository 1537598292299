import "./PlanCard.scss"
import { CoreButton, CoreTitle } from "@packages";

interface IPlanCardProps {
    title: string;
    subtitle: string;
    price: string;
    period: string;
    buttonText: string;
    onButtonClick: () => void
}
const PlanCard = ({ title, subtitle, price, period, buttonText,onButtonClick }: IPlanCardProps) => {

    return (
        <div className="plan-card-wrapper">
            <div className="plan-card-header">
                <CoreTitle tag='h4' text={title} />
                <p>{subtitle}</p>
            </div>

            <div className="plan-card-footer">
                <p className="footer-text"><span> {price}</span>{period}</p>
                <CoreButton type='primary' text={buttonText} isFullWidth={true} onClick={onButtonClick} />
            </div>
        </div>
    )
}

export default PlanCard;