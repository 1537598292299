import React, { MouseEventHandler, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input, message } from "antd";
import { CoreButton, CoreTitle } from "../../packages";
import "./CoreTwoStepVerificationPage.scss";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { post2FALogin, select2FARequiredData } from "@store/slices/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import { getOrganizations } from "@store/slices/organizationSlice";
import { useTranslation } from "react-i18next";


interface CoreTwoFactorVerificationPageProps {
    title: string;
    subTitle: string;
    onClick?:
    | (MouseEventHandler<HTMLAnchorElement> &
        MouseEventHandler<HTMLButtonElement>)
    | undefined;
}

interface ITwoFactorVerificationPage {
    verificationOne: string;
    verificationTwo: string;
    verificationThree: string;
    verificationFour: string;
    verificationFive: string;
    verificationSix: string;
}

const CoreTwoFactorVerificationPage = (props: CoreTwoFactorVerificationPageProps) => {
    const { t } = useTranslation();
    const { title = "", subTitle = "" } = props;
    const requiredData = useAppSelector(select2FARequiredData);
    const [inputFiled1, setInputFiled1] = useState<string>('');
    const [inputFiled2, setInputFiled2] = useState<string>('');
    const [inputFiled3, setInputFiled3] = useState<string>('');
    const [inputFiled4, setInputFiled4] = useState<string>('');
    const [inputFiled5, setInputFiled5] = useState<string>('');
    const [inputFiled6, setInputFiled6] = useState<string>('');

    const [isLoading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const dispatch = useAppDispatch()
    const {
        control,
        handleSubmit,
    } = useForm<ITwoFactorVerificationPage>({ mode: "onChange" });


    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);

    const concatInputValues = [inputFiled1, inputFiled2, inputFiled3, inputFiled4, inputFiled5, inputFiled6].join('')
    const isValid = concatInputValues?.length === 6 ? true : false;

    const handleInputChange = (stateFiled: React.Dispatch<React.SetStateAction<string>>) => ({ e, inputRef }: { e: any, inputRef: any }) => {
        const value = e.target?.value?.slice(-1);
        if (value) {
            stateFiled(value)
            inputRef.current.focus();
        } else {
            stateFiled('')
        }
    };


    const redirectPageAfterLogin = async () => {
        await dispatch(getOrganizations())
            .then(unwrapResult)
            .then((organizations) => {
                if (organizations?.length === 0 || !organizations) {
                    navigate('/organization-initiate')
                } else if (organizations.length === 1 && !organizations[0]?.Workflow?.Completed) {
                    if (organizations[0]?.Workflow?.CurrentIndex === 0) {
                        navigate(`/organization-setup/${organizations[0].OrganizationId}/organization-information`);
                    }
                    if (organizations[0]?.Workflow?.CurrentIndex === 1) {
                        navigate(`/organization-setup/${organizations[0].OrganizationId}/entity`);
                    }
                    if (organizations[0]?.Workflow?.CurrentIndex === 2) {
                        navigate(`/organization-setup/${organizations[0].OrganizationId}/invoice-phase`);
                    }
                    if (organizations[0]?.Workflow?.CurrentIndex === 3) {
                        navigate(`/organization-setup/${organizations[0].OrganizationId}/device`);
                    }
                } else {
                    navigate('/organization', { replace: true });
                }
            });
    }

    const onSubmit = (data: ITwoFactorVerificationPage) => {
        const codes = concatInputValues;
        setLoading(true);
        dispatch(post2FALogin({ OTP: codes, Token: requiredData.token, App: requiredData.app }))
            .then(unwrapResult)
            .then((result) => {
                setLoading(false);
                redirectPageAfterLogin();
            })
            .catch((error) => {
                message.error(error?.message || 'Token Invalid');
                setLoading(false);
            });
    };

    if (!requiredData?.token || !requiredData.app) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="core-two-factor-verification-wrapper">
            <div className="core-two-factor-verification-content">
                <div>
                    <CoreTitle tag="h1" text={title} />
                    <p className="core-two-factor-verification-sub-title">{subTitle}</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="two-factor-verification-input">
                        <div className='general-input-wrapper'>
                            <Controller
                                name="verificationOne"
                                render={({ field }) => <Input {...field}
                                    value={inputFiled1}
                                    ref={inputRef1}
                                    onChange={(e) => handleInputChange(setInputFiled1)({
                                        e: e,
                                        inputRef: inputRef2
                                    })}
                                    maxLength={1}
                                    placeholder="0"
                                    className="verification-input" />}
                                control={control}
                            />
                        </div>
                        <div className='general-input-wrapper'>
                            <Controller
                                name="verificationTwo"
                                render={({ field }) => <Input {...field} placeholder="0"
                                    value={inputFiled2}
                                    ref={inputRef2}
                                    onChange={(e) => handleInputChange(setInputFiled2)({
                                        e: e,
                                        inputRef: inputRef3
                                    })}
                                    maxLength={1}
                                    className="verification-input" />}
                                control={control}
                            />
                        </div>
                        <div className='general-input-wrapper'>
                            <Controller
                                name="verificationThree"
                                render={({ field }) => <Input {...field} placeholder="0"
                                    value={inputFiled3}
                                    ref={inputRef3}
                                    onChange={(e) => handleInputChange(setInputFiled3)({
                                        e: e,
                                        inputRef: inputRef4
                                    })}
                                    maxLength={1}
                                    className="verification-input" />}
                                control={control}
                            />
                        </div>
                        <div className='general-input-wrapper'>
                            <Controller
                                name="verificationFour"
                                render={({ field }) => <Input {...field} placeholder="0"
                                    value={inputFiled4}
                                    ref={inputRef4}
                                    onChange={(e) => handleInputChange(setInputFiled4)({
                                        e: e,
                                        inputRef: inputRef5
                                    })}
                                    maxLength={1}
                                    className="verification-input" />}
                                control={control}
                            />
                        </div>
                        <div className='general-input-wrapper'>
                            <Controller
                                name="verificationFive"
                                render={({ field }) => <Input {...field} placeholder="0"
                                    value={inputFiled5}
                                    onChange={(e) => handleInputChange(setInputFiled5)({
                                        e: e,
                                        inputRef: inputRef6
                                    })}
                                    ref={inputRef5}
                                    maxLength={1}
                                    className="verification-input" />}
                                control={control}
                            />
                        </div>
                        <div className='general-input-wrapper'>
                            <Controller
                                name="verificationSix"
                                render={({ field }) => <Input {...field} placeholder="0"
                                    value={inputFiled6}
                                    ref={inputRef6}
                                    onChange={(e) => handleInputChange(setInputFiled6)({
                                        e: e,
                                        inputRef: inputRef6
                                    })}
                                    maxLength={1}
                                    className="verification-input" />}
                                control={control}
                            />
                        </div>
                    </div>

                    {/*<Checkbox className="general-checkbox margin-bottom-30">Don't ask again on this computer</Checkbox>*/}
                    <CoreButton
                        type="primary"
                        htmlType="submit"
                        disabled={!isValid}
                        isFullWidth={true}
                        text="Verify "
                        loading={isLoading}
                    />
                    <p>Go back to
                        <span className="sign-in-link" onClick={() => navigate('/login')}>{t('SIGN_IN')} </span>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default CoreTwoFactorVerificationPage;
