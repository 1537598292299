import './DashboardLineChart.scss';
import { CoreTitle } from '@packages';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { FC } from 'react';
import { IDashboardCharts } from '@models/dashboard.model';
import { Spin } from 'antd';
import { displayCurrency } from '@utils/displayCurrency';

interface DashboardLineChartProps {
    data: IDashboardCharts;
    total: number;
    totalNumberType?: 'currency' | 'number';
    title: string;
    header_title: string;
    stroke_color: string;
    dashboardChartsLoading: boolean;
}

const DashboardLineChart: FC<DashboardLineChartProps> = ({
    data,
    total = 0,
    title,
    header_title,
    stroke_color,
    dashboardChartsLoading,
    totalNumberType = 'currency',
}) => {
    const formattedTotal =
        totalNumberType === 'currency'
            ? displayCurrency({ Amount: total, Code: 'SAR' })
            : `${total}`;

    return (
        <div className='dashboard-line-chart-wrapper'>
            <CoreTitle tag='h6' text={title} />
            <div className='line-chart-header-block'>
                <div>
                    <p className='line-chart-header'>{header_title}</p>
                    <CoreTitle tag='h4' text={formattedTotal} />
                </div>
            </div>

            <div className='line-chart'>
                <ResponsiveContainer width='100%' height={250}>
                    {dashboardChartsLoading ? (
                        <div className='loading-spinner'>
                            <Spin size='large' />
                        </div>
                    ) : (
                        <LineChart data={data?.Last7DaySums} margin={{ top: 25 }}>
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='DateTime' padding={{ left: 20, right: 20 }} />
                            <YAxis />
                            <Tooltip />
                            <Line
                                type='monotone'
                                dataKey={
                                    title === 'Total Invoices Interfaced'
                                        ? 'TotalB2BandB2CGeneratedCount'
                                        : 'TotalB2BandB2CTaxAmountSum'
                                }
                                stroke={stroke_color}
                                dot={false}
                            />
                        </LineChart>
                    )}
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default DashboardLineChart;
