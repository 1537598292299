import PlanCard from '../plan-card/PlanCard';
import './AnnualPrice.scss';
import { Col, Row, Table } from 'antd';
import { useEffect } from 'react';
import { ColumnsType } from 'antd/es/table';
import { CheckCircleOutlined, MinusOutlined } from '@ant-design/icons';
import { getTiers, plansTableDataSelector, tierData } from '@store/slices/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useNavigate } from 'react-router-dom';

export interface IAnnualPriceProps {
    type: 'month' | 'year';
}

export default function PriceCard({ type }: IAnnualPriceProps) {
    const dispatch = useAppDispatch();
    const tiers = useAppSelector(tierData);
    const tableData = useAppSelector(plansTableDataSelector);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getTiers());
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: 'Features',
            dataIndex: 'Features',
            render: (text) => <div className='invoice-feature-data'>{text}</div>,
        },
        ...tiers.map((tier) => ({
            title: tier.Name,
            dataIndex: tier.Name.toLowerCase().replace(/\s/g, '_'),
            render: (text: string | boolean) => (
                <div className='pricing-table-data'>
                    {text === false ? (
                        <MinusOutlined />
                    ) : text === true ? (
                        <CheckCircleOutlined className='check-outlined' />
                    ) : (
                        text
                    )}
                </div>
            ),
        })),
    ];

    return (
        <div className='annual-plan-wrapper'>
            <Row gutter={24}>
                {tiers?.length &&
                    tiers.map((data) => (
                        <Col
                            span={12}
                            md={12}
                            lg={8}
                            key={data.ItemId}
                            style={{ marginBottom: '20px' }}
                        >
                            <PlanCard
                                title={data?.Name}
                                subtitle={data?.Description}
                                price={`${
                                    type === 'month' ? data.MonthlyPrice : data.YearlyPrice
                                } ${data?.Currency}`}
                                period={`Per ${type === 'month' ? 'month' : 'year'}`}
                                buttonText='Subscribe'
                                onButtonClick={() =>
                                    navigate(
                                        `/subscribe/manage-subscription?id=${data.ItemId}&type=${type}`,
                                    )
                                }
                            />
                        </Col>
                    ))}
            </Row>

            <div className='margin-top-80'>
                <Table columns={columns} dataSource={tableData} pagination={false} bordered />
            </div>
        </div>
    );
}
