import InlineGenericInput from '@components/common/InlineGenericInput';
import { CoreButton } from '@packages';
import { Modal } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import './AllowanceCharges.scss';
import { addAllowanceCharge } from '@store/slices/einvoiceSlice';
import { useAppDispatch } from '@store/hooks';
import { useTranslation } from 'react-i18next';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { ITaxCategory, TAX_CATEGORY } from '@utils/InvoiceUtils';
import { AllowanceTax, IAllowanceCharge } from '@models/invoice.model';
import {
    AllowanceChargesReason,
    IAllowanceChargesReason,
} from '@utils/invoiceAllowanceChargeReason';

interface CreateAllowanceCharges {
    isShowModal: boolean;
    setShowModal: (isShow: boolean) => void;
    discountType: DiscountType;
}

export type DiscountType = 'Charge' | 'Discount';

const CreateAllowanceCharges: FC<CreateAllowanceCharges> = ({
    isShowModal,
    setShowModal,
    discountType,
}) => {
    const [charge, setCharge] = useState<ITaxCategory | undefined>();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue: setFormValue,
    } = useForm<IAllowanceCharge>({
        mode: 'onChange',
        defaultValues: {},
    });
    const onSubmit = (data: IAllowanceCharge) => {
        const allowanceTax: AllowanceTax = {
            Category: charge?.value || '',
            Percent: charge?.percent || 0,
        };
        const allowanceCharge: IAllowanceCharge = {
            ...data,
            Amount: Number(data?.Amount),
            AllowanceTax: allowanceTax,
            ChargeIndicator: discountType === 'Charge' ? true : false,
        };
        console.log(charge);
        dispatch(addAllowanceCharge(allowanceCharge));
        onHideModal();
    };
    const onHideModal = () => {
        setShowModal(false);
        setCharge(undefined);
        reset();
    };
    return (
        <Modal
            closable={false}
            open={isShowModal}
            maskClosable={false}
            footer={[]}
            title={
                discountType == 'Discount' ? t('ADD_DOCUMENT_LEVEL_DISCOUNT') : t('ADD_ALLOWANCE_CHARGE')
            }
        >
            <form>
                <div className='new-form-inner-wrapper'>
                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        type='number'
                        dataKey='Amount'
                        level='AMOUNT'
                        rules={{
                            required: true,
                        }}
                        marginBottom={15}
                        errorMessageSize='small'
                    />

                    <InlineGenericSelect
                        levelCol={24}
                        inputCol={24}
                        control={control}
                        errors={errors}
                        dataKey='Reason'
                        level={t('REASON')}
                        placeHolder={t('SELECT_REASON')}
                        options={AllowanceChargesReason}
                        rules={{
                            required: true,
                        }}
                        showSearch={true}
                        setFormValue={setFormValue}
                        marginBottom={0}
                        errorMessageSize='small'
                        onChange={(value, option: IAllowanceChargesReason) => {
                            setFormValue('ReasonCode', option?.reasonCode);
                        }}
                    />

                    <InlineGenericInput
                        control={control}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='ReasonCode'
                        level='REASON_CODE'
                        rules={{
                            required: true,
                        }}
                        marginBottom={15}
                        errorMessageSize='small'
                        disabled={true}
                    />

                    <InlineGenericSelect
                        control={control}
                        options={TAX_CATEGORY}
                        errors={errors}
                        levelCol={24}
                        inputCol={24}
                        dataKey='Charge'
                        level={t('TAX_RATE')}
                        placeHolder={t('SELECT_TAX_RATE')}
                        rules={{
                            required: true,
                        }}
                        marginBottom={15}
                        setFormValue={setFormValue}
                        errorMessageSize='small'
                        onChange={(value, options) => {
                            console.log(value, options);
                            setCharge(options);
                        }}
                    />

                    <div key='footer' className='modal-footer'>
                        <CoreButton
                            key={1}
                            text={t('CANCEL')}
                            htmlType='button'
                            type='basic'
                            onClick={onHideModal}
                            className='cancel-button'
                        />
                        <CoreButton
                            key={2}
                            text={t('ADD')}
                            type='primary'
                            onClick={() => {
                                handleSubmit(onSubmit)();
                            }}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default CreateAllowanceCharges;
