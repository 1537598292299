import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IEntity } from '@models/organization.model';
import { processQuery } from '@utils/processQuery';
import { AxiosError } from 'axios';

export const EntityAPI = {
    getActiveEntities: async (OrganizationId: string): Promise<IEntity[]> => {
        const url =
            APP_CONFIG.eisUrl +
            `OrganizationQuery/ActiveEntities${processQuery({ OrganizationId })}`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return (response?.data?.Result as IEntity[]) || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
