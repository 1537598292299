import { CoreButton } from '@packages';
import './InvoiceProcessingSummary.scss';
import { ProcessingSummaryCard } from '../../index';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { dashboardFilter, getProcessingSummary, selectProcessingSummary } from '@store/slices/dashboardSlice';
import { useEffect, useState } from 'react';
import { propagateTotalCounts } from "@utils/calculateInvoiceSummary";
import { useTranslation } from 'react-i18next';

const InvoiceProcessingSummary = () => {
    const { t } = useTranslation();
    const processingSummaryData = useAppSelector(selectProcessingSummary);
    const dispatch = useAppDispatch();
    const [isResetChild, setResetChild] = useState<boolean>(false);
    const [resetClicked, setResetClicked] = useState<boolean>(false);
    const dashboardFilterDate = useAppSelector(dashboardFilter)

    useEffect(() => {
        dispatch(
            getProcessingSummary(dashboardFilterDate),
        );
    }, []);

    useEffect(() => {
        setResetChild(true);
        setTimeout(() => setResetChild(false), 100);
        setResetClicked(false)
    }, [resetClicked]);

    return (
        <div>
            <div className='invoice-summary-container'>
                <div className='summary-text'>{t("INVOICE_PROCESSING_SUMMARY")}</div>
                <div className='right-content'>
                    <CoreButton className='summary-actions' text={t('RESET')} onClick={() => setResetClicked(true)} />
                </div>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
                {!isResetChild ? (
                    <ProcessingSummaryCard
                        summary={processingSummaryData}
                        total={propagateTotalCounts(processingSummaryData)}
                        title={t('E_INVOICE_SOLUTIONS')}
                    />
                ) : (
                    <></>
                )}

            </div>
        </div>
    );
};

export default InvoiceProcessingSummary;
