/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { isLoginUser } from '@store/slices/authSlice';
import { getSubscription } from '@store/slices/subscribeSlice';
import { isAppUser } from '@store/slices/userSlice';
import { useEffect } from 'react';

export default function useFetchInitialAppUserData() {
    const isAppLoginUser = useAppSelector(isAppUser);
    const isLogin = useAppSelector(isLoginUser);

    const dispatch = useAppDispatch();

    const fetchAppUserInfo = () => {
        dispatch(getSubscription());
    };

    useEffect(() => {
        if (!isAppLoginUser || !isLogin) return;
        fetchAppUserInfo();
    }, [isAppLoginUser, isLogin]);
}
