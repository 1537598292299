import { CoreTitle } from '@packages';
import { RootState } from '@store/store';
import { Divider, Dropdown, Space, Image, Badge } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ProfileDropDown from '../profile-drop-down/ProfileDropDown';
import { useGetHeaderTitle } from '@hooks';
import avatar from '../../../assets/images/Avatar.png';
import { DownArrowIcon } from '@components/icons';
import './LayoutWrapper.scss';
import { BellOutlined } from '@ant-design/icons';
import LanguageMenu from './LanguageMenu';
import { NotificationDropDown } from '@components';
import { useTranslation } from 'react-i18next';
import NavigationDrawer from '@components/main-layout/layout-wrapper/navigation-drawer/NavigationDrawer';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@store/hooks';
import { userSelectRole } from '@store/slices/userSlice';
import SubscriptionUpgradePanel from '@components/subscription-upgrade-panel/SubscriptionUpgradePanel';

const NavigationBar = () => {
    const { FirstName, LastName, DisplayPictureUrl } = useSelector(
        (state: RootState) => state.user.userDetails,
    );
    const { isAdmin, isAppUser } = useAppSelector(userSelectRole);
    const { t } = useTranslation();
    const headerTitle = useGetHeaderTitle();

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notificationDropdownVisible, setNotificationDropdownVisible] = useState(false);

    const handleVisibleChange = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleNotification = () => {
        setNotificationDropdownVisible(!notificationDropdownVisible);
    };

    return (
        <Header className='layout-header'>
            <CoreTitle tag='h4' text={t(headerTitle)} />

            <NavigationDrawer />

            <div className='action-group'>
                {isAppUser && <SubscriptionUpgradePanel />}

                <Divider type='vertical' className='vertical-divider' />
                <LanguageMenu />
                <Divider type='vertical' className='vertical-divider' />

                <Dropdown
                    open={notificationDropdownVisible}
                    trigger={['click']}
                    dropdownRender={(menu) => <NotificationDropDown />}
                    onOpenChange={handleNotification}
                >
                    <Badge count={0} className='bandage'>
                        <BellOutlined className='notification-bar' />
                    </Badge>
                </Dropdown>

                <Divider type='vertical' className='vertical-divider' />

                <Dropdown
                    open={dropdownVisible}
                    trigger={['click']}
                    dropdownRender={(menu) => (
                        <ProfileDropDown handleVisibleChange={handleVisibleChange} />
                    )}
                    onOpenChange={handleVisibleChange}
                >
                    <Space className='drop-down-profile-button'>
                        <Image
                            className='profile-image'
                            width={30}
                            height={30}
                            src={DisplayPictureUrl || avatar}
                            preview={false}
                        />

                        <div className='org-name'>
                            {FirstName} {LastName}
                            <DownArrowIcon
                                className={
                                    dropdownVisible ? 'drop-down-icon-clicked' : 'drop-down-icon'
                                }
                            />
                        </div>
                    </Space>
                </Dropdown>
            </div>
        </Header>
    );
};

export default NavigationBar;
