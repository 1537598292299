const ENVIRONMENT = process.env;

const CONFIG = {
    identityUrl: `${ENVIRONMENT.REACT_APP_API_IAM_URL}/Identity/`,
    eisUrl: `${ENVIRONMENT.REACT_APP_API_EIS_URL}/EInvoiceService/`,
    mailUrl: `${ENVIRONMENT.REACT_APP_API_MAIL_URL}/MailService/`,
    notificationUrl: `${ENVIRONMENT.REACT_APP_API_NOTIFICATION_URL}/Notification/`,
    storageUrl: `${ENVIRONMENT.REACT_APP_API_STORAGE_URL}/Storage/`,
    mfaUrl: `${ENVIRONMENT.REACT_APP_API_MFA_URL}/MultiFactorAuthentication/`,
    stsUrl: `${ENVIRONMENT.REACT_APP_API_STS_URL}/SystemTokenService/`,
    pdfEngineUrl: `${ENVIRONMENT.REACT_APP_API_ENGINE_URL}/PDFEngine/`,
    stripeApiKey: ENVIRONMENT.REACT_APP_API_STRIPE_KEY,
};

export const APP_CONFIG = {
    ...CONFIG,
};

export const DEFAULT_EMPTY_VALUE = '---';
export const DEFAULT_TABLE_SIZE = 10;
export const DEFAULT_DELAY_TIME = 1000;
export const DEFAULT_LONG_DELAY_TIME = 4000;
export const DEFAULT_DATE_VALUE = '0001-01-01T00:00:00Z';

export const LANGUAGE_KEY = 'language';
export const DEFAULT_LANGUAGE = 'en';
