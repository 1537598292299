import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

export interface IPdfDownloadModel {
    html: string;
    type: 'download';
    name: string;
}
export interface IPdfPreviewModel {
    html: string;
    type: 'preview';
}

export type IPdfModel = IPdfDownloadModel | IPdfPreviewModel;
export const htmlToPdfGenerator= ({ type, html, ...others }: IPdfModel) => {
    // Create a temporary element to hold the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    document.body.appendChild(tempDiv);

    html2canvas(tempDiv).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;
        let pageNumber = 1;
        // Set font size to 12px (default in jsPDF is points, so 12px ≈ 8.96 points)
        pdf.setFontSize(12);
        // Add first page with page number
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.text(`Page ${pageNumber}`, 10, pageHeight - 10); // Align to the left (10mm from the left) at the bottom
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            pageNumber++;
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.text(`Page ${pageNumber}`, 10, pageHeight - 10); // Align to the left (10mm from the left) at the bottom
            heightLeft -= pageHeight;
        }

        if (type === 'download') {
            pdf.save((others as IPdfDownloadModel).name);
        }

        if (type === 'preview') {
            // Open the PDF in a new tab
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl); // Opens the PDF in a new tab
        }
    });

    // Remove the temporary element after generating the PDF
    document.body.removeChild(tempDiv);
};
