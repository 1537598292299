import InlineGenericInput from '@components/common/InlineGenericInput';
import InlineGenericRadio from '@components/common/InlineGenericRadio';
import DiscountDisplay from './DiscountDisplay';
import { IInvoiceItemCharges, IInvoiceLinesItem } from '@models/invoice.model';
import { CoreButton } from '@packages';
import { Col, Modal, Row } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { addInvoiceLineCharge, selectInvoiceLineById } from '@store/slices/einvoiceSlice';
import { useAppDispatch } from '@store/hooks';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import {
    AllowanceChargesReason,
    IAllowanceChargesReason,
} from '@utils/invoiceAllowanceChargeReason';

interface IDiscountAmountLineProps {
    isOpenModal: boolean;
    data: IInvoiceLinesItem;
    updateInvoiceLine: (itemId: string) => (object: Partial<IInvoiceLinesItem>) => void;
    documentTaxCurrencyCode?: string;
    setHideModal: () => void;
    discountType: DiscountType;
}

type InvoiceDiscountOption = Pick<
    IInvoiceItemCharges,
    'BaseAmount' | 'Amount' | 'Reason' | 'ReasonCode'
> & {
    Type: 'percentage' | 'amount';
    Percentage?: number;
};

export type DiscountType = 'discount' | 'charge';

function DiscountAmountLine({
    isOpenModal,
    discountType,
    data,
    setHideModal,
}: IDiscountAmountLineProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const invoiceLine = useSelector((state: RootState) =>
        selectInvoiceLineById(state, data.SerialId),
    );

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        watch,
        reset,
        clearErrors,
    } = useForm<InvoiceDiscountOption>({
        mode: 'onChange',
        defaultValues: {
            Type: 'percentage',
        },
    });

    const onSubmit = (lineData: InvoiceDiscountOption) => {
        const lineCharge = getInvoiceLines({ ...lineData, DiscountType: discountType });
        dispatch(addInvoiceLineCharge({ LineCharge: lineCharge, ItemId: data.SerialId }));
        resetDiscount();
    };
    const title = discountType === 'charge' ? 'Item Charge' : 'ITEM_DISCOUNT';
    const buttonTitle = discountType === 'charge' ? t('ADD_CHARGE') : t('ADD_DISCOUNT');
    const resetDiscount = () => {
        reset({});
    };

    return (
        <Modal width={500} open={isOpenModal} closable={false} title={t(title)} footer={[]}>
            <DiscountDisplay invoiceLine={invoiceLine} discountType={discountType} />
            <form>
                <div className='new-form-inner-wrapper'>
                    <div style={{ marginTop: 20 }}></div>
                    <InlineGenericRadio
                        setFormValue={setFormValue}
                        control={control}
                        errors={errors}
                        clearErrors={clearErrors}
                        options={[
                            { label: `${t('Percentage')}`, value: 'percentage' },
                            { label: `${t('Amount')}`, value: 'amount' },
                        ]}
                        dataKey='Type'
                        level={t('Item Type')}
                        rules={{
                            required: true,
                        }}
                        levelCol={24}
                        inputCol={24}
                        marginBottom={20}
                        errorMessageSize='small'
                    />
                    {watch('Type') === 'percentage' && (
                        <>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <InlineGenericInput
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        type='number'
                                        dataKey='BaseAmount'
                                        level='BASE_AMOUNT'
                                        rules={{
                                            required: true,
                                        }}
                                        marginBottom={15}
                                        errorMessageSize='small'
                                    />
                                </Col>
                                <Col span={12}>
                                    <InlineGenericInput
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        type='number'
                                        dataKey='Percentage'
                                        level='Percentage'
                                        rules={{
                                            required: true,
                                            maxLength: 100,
                                        }}
                                        marginBottom={15}
                                        errorMessageSize='small'
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                    {watch('Type') === 'amount' && (
                        <>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                type='number'
                                dataKey='Amount'
                                level='Amount'
                                rules={{
                                    required: true,
                                    maxLength: 100,
                                }}
                                marginBottom={15}
                                errorMessageSize='small'
                            />
                        </>
                    )}
                    <Row gutter={20} style={{ marginBottom: 10 }}>
                        <Col span={12}>
                            <InlineGenericSelect
                                levelCol={24}
                                inputCol={24}
                                control={control}
                                errors={errors}
                                dataKey='Reason'
                                level={t('REASON')}
                                options={AllowanceChargesReason}
                                rules={{
                                    required: true,
                                }}
                                showSearch={true}
                                setFormValue={setFormValue}
                                marginBottom={0}
                                errorMessageSize='small'
                                onChange={(value, option: IAllowanceChargesReason) => {
                                    setFormValue('ReasonCode', option?.reasonCode);
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='ReasonCode'
                                level='REASON_CODE'
                                rules={{
                                    required: true,
                                }}
                                marginBottom={15}
                                errorMessageSize='small'
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <div  style={{marginTop: '10px'}}>
                        <CoreButton
                            key={2}
                            text={t(buttonTitle)}
                            type='primary'
                            onClick={() => {
                                handleSubmit(onSubmit)();
                            }}
                        />
                    </div>
                </div>

                <div key='footer' className='modal-footer'>
                    <CoreButton
                        key={1}
                        text={t('CLOSE')}
                        htmlType='button'
                        type='basic'
                        onClick={() => {
                            setHideModal();
                            resetDiscount();
                        }}
                        className='cancel-button'
                    />
                </div>
            </form>
        </Modal>
    );
}

export default DiscountAmountLine;

function getInvoiceLines({
    Amount = 0,
    Percentage = 0,
    BaseAmount = 1,
    DiscountType,
    Type,
    ...others
}: InvoiceDiscountOption & { DiscountType: DiscountType }): Partial<IInvoiceItemCharges> {
    const linesChange: IInvoiceItemCharges = {} as IInvoiceItemCharges;
    linesChange.Amount =
        Type === 'percentage' ? getAmountOfPercent(BaseAmount, Percentage) : Number(Amount);
    linesChange.ChargeIndicator = DiscountType === 'discount' ? false : true;
    linesChange.BaseAmount = Type === 'percentage' ? Number(BaseAmount) : undefined;
    linesChange.MultiplierFactorNumeric = Type === 'percentage' ? Number(Percentage) : undefined;
    return { ...linesChange, ...others };
}

function getAmountOfPercent(baseAmount: number, percent: number): number {
    return (Number(baseAmount) * Number(percent)) / 100;
}
