import { RootState } from "@store/store";
import { useSelector } from "react-redux";
import React, { FC } from "react";
import { UserRolesValues } from "@config/user-roles";

interface ICoreRoleGuardProps {
    allowedRoles: UserRolesValues[];
    children: React.ReactNode;
}

export const CoreRoleGuard: FC<ICoreRoleGuardProps> = ({ allowedRoles = [], children }) => {
    const { Roles } = useSelector((state: RootState) => state.user.userDetails);
    const hasPermission = allowedRoles.some((data) => Roles.includes(data));
    if (!hasPermission) {
        return null;
    }
    return <>{children}</>
}
export default CoreRoleGuard;