export function checkCharacterInput(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[a-zA-Z\s]+$/;
    const char = String.fromCharCode(charCode);
    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkNumberInput(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[0-9+]+$/;
    const char = String.fromCharCode(charCode);
    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkOtherSellerId(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[0-9]+$/;
    const char = String.fromCharCode(charCode);

    const inputValue = e.target.value || '';

    if (inputValue.replace(/[^0-9]/g, '').length >= 10) {
        e.preventDefault();
        return;
    }

    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkPostalCode(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[0-9]+$/;
    const char = String.fromCharCode(charCode);

    const inputValue = e.target.value || '';

    if (inputValue.replace(/[^0-9]/g, '').length >= 5) {
        e.preventDefault();
        return;
    }

    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkVatRegistrationNumber(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[0-9+]+$/;
    const char = String.fromCharCode(charCode);

    const inputValue = e.target.value || '';

    if (inputValue.replace(/[^0-9]/g, '').length >= 15) {
        e.preventDefault();
        return;
    }

    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkPhoneNumber(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[0-9+]+$/;
    const char = String.fromCharCode(charCode);

    const inputValue = e.target.value || '';

    if (inputValue.replace(/[^0-9]/g, '').length >= 14) {
        e.preventDefault();
        return;
    }

    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkBuildingNumber(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[0-9+]+$/;
    const char = String.fromCharCode(charCode);

    const inputValue = e.target.value || '';

    if (inputValue.replace(/[^0-9]/g, '').length >= 4) {
        e.preventDefault();
        return;
    }

    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkArabicCharacterInput(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /[\u0600-\u06FF\s]/;
    const char = String.fromCharCode(charCode);
    if (!regex.test(char)) {
        e.preventDefault();
    }
}

export function checkUnitName(e: any) {
    const charCode = e.which || e.keyCode;
    const regex = /^[a-zA-Z0-9]*$/;

    if (charCode === 32) {
        return;
    }

    const char = String.fromCharCode(charCode);
    const inputValue = e.target.value || '';

    if (!regex.test(char) || (inputValue + char).length > 255) {
        e.preventDefault();
    }
}
