import React, { useEffect, useState } from 'react';
import { UserOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Divider, Image, Tooltip } from 'antd';
import './ProfileDropDown.scss';
import avatar from '../../../assets/images/Avatar.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { logOutUser } from '@store/slices/authSlice';
import CoreRoleGuard from '../../../packages/CoreRoleGuard/CoreRoleGuard';
import {
    isAdminUser,
    onClearUserInfo,
    selectLoggedUserInfo,
    userSelectRole,
} from '@store/slices/userSlice';
import { getOrganizations } from '@store/slices/organizationSlice';
import { OrganizationIcon } from '@components/icons';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { DEFAULT_EMPTY_VALUE } from '@config/constants';
import { useTranslation } from 'react-i18next';

interface ProfileDropDownProps {
    handleVisibleChange: () => void; // Define the type of the function prop
}

const ProfileDropDown: React.FC<ProfileDropDownProps> = ({ handleVisibleChange }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isScrollOrganization, setIsScrollOrganization] = useState<boolean>(false);

    const logOutPortal = async () => {
        await dispatch(onClearUserInfo());
        await dispatch(logOutUser());
    };

    const { isAdmin, isAppUser } = useAppSelector(userSelectRole);

    useEffect(() => {
        dispatch(getOrganizations());
    }, []);

    const { organizations } = useSelector((state: RootState) => state.organization);

    const { UserId, FirstName, LastName, Email, DisplayPictureUrl } =
        useAppSelector(selectLoggedUserInfo).userDetails;

    const navigate = useNavigate();

    const onOrganizationClick = (orgId: string) => {
        handleVisibleChange();
        navigate(`/organizations/${orgId}`);
    };

    const truncateEmail = (email: string): string => {
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
            const truncatedPart = email.substring(0, atIndex);
            const remainingPart = email.substring(atIndex);

            if (truncatedPart.length <= 18) {
                return email;
            }
            return truncatedPart.substring(0, 14) + '...' + remainingPart;
        }
        return email;
    };

    return (
        <div className='drop-down-profile'>
            <div className='profile-details'>
                <Image
                    className='profile-image'
                    width={60}
                    height={60}
                    src={DisplayPictureUrl || avatar}
                    preview={false}
                />
                <p className='user-name'>
                    {FirstName} {LastName}
                </p>
                <p className='user-handles'>
                    {' '}
                    {t('USER_ID')}: {UserId.slice(-12)}
                </p>
                <p className='user-handles'>
                    {t('SUBSCRIPTION_ID')}: {organizations[0]?.SubscriptionId.slice(-12)}
                </p>

                <Tooltip
                    placement='bottom'
                    title={Email}
                    overlayInnerStyle={{ textAlign: 'center' }}
                >
                    <p className='user-handles-email'>
                        {t('EMAIL')}: {truncateEmail(Email)}
                    </p>
                </Tooltip>
            </div>
            <Divider className='custom-divider' />
            {!isAdmin && (
                <>
                    <div className='my-organization'>
                        <div className='my-organization-heading'>
                            <p>{t('MY_ORGANIZATION')}</p>
                            <CoreRoleGuard allowedRoles={['appuser']}>
                                <NavLink to={'organizations'} className='manage-button'>
                                    {t('MANAGE')}
                                </NavLink>
                            </CoreRoleGuard>
                        </div>
                    </div>

                    <div className='organizations-list-wrapper'>
                        <div
                            className='organization-scroller'
                            style={{
                                overflowY: isScrollOrganization ? 'scroll' : 'hidden',
                                height: isScrollOrganization ? '160px' : '100px',
                            }}
                        >
                            {organizations.map((organization) => (
                                <div
                                    className='organization-list'
                                    key={organization?.OrganizationId}
                                >
                                    <div className='org-icon'>
                                        <OrganizationIcon />
                                    </div>
                                    <div
                                        className='organization-details'
                                        onClick={() =>
                                            onOrganizationClick(organization?.OrganizationId)
                                        }
                                    >
                                        <p className='user-name'>
                                            {organization?.LegalRegistrationNameEn ||
                                                DEFAULT_EMPTY_VALUE}
                                        </p>
                                        <p className='user-handles'>
                                            {t('ORGANIZATION_ID')}:{' '}
                                            {organization?.OrganizationId.slice(-12)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {organizations.length >= 3 && !isScrollOrganization && (
                            <div className='see-more' onClick={() => setIsScrollOrganization(true)}>
                                {t('SEE_MORE')}
                            </div>
                        )}
                    </div>
                    <Divider className='custom-divider' />
                </>
            )}

            <div className='action-buttons'>
                {isAppUser && (
                    <div className='button-box'>
                        <UserOutlined />
                        <NavLink to={'/subscribe'} className='button-label'>
                            {t('SUBSCRIPTION')}
                        </NavLink>
                    </div>
                )}

                <div className='button-box'>
                    <UserOutlined />
                    <NavLink to={'/profile'} className='button-label'>
                        {t('MY_ACCOUNT')}
                    </NavLink>
                </div>
                <div className='button-box'>
                    <SettingOutlined />
                    <NavLink to={'/setting'} className='button-label'>
                        {t('SETTINGS')}
                    </NavLink>
                </div>
                <div className='button-box'>
                    <LogoutOutlined />
                    <NavLink to={'/login'} className='button-label' onClick={logOutPortal}>
                        {t('SIGN_OUT')}
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default ProfileDropDown;
