import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const UpgradeSvg = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_9303_13277)">
            <rect x="2" y="1" width="24" height="24" rx="5.33333" fill="#133FE2" />
            <rect x="2.33333" y="1.33333" width="23.3333" height="23.3333" rx="5" stroke="#133FE2"
                  strokeWidth="0.666667" />
            <path d="M14.0002 9.11108V16.8889M10.1113 13H17.8891" stroke="white" strokeWidth="1.11333"
                  strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <filter id="filter0_d_9303_13277" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix type="matrix"
                               values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9303_13277" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9303_13277" result="shape" />
            </filter>
        </defs>
    </svg>

);

const UpgradeIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={UpgradeSvg} {...props} />
);

export default UpgradeIcon;
