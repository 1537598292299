export function isCardExpired(expMonth: number, expYear: number): boolean {
    // Get the current date
    if (!expMonth || expYear) return true;

    const currentDate = new Date();

    // Parse the expiration date from the input (format: MM/YY)

    // Convert the expiration year to a full year
    const fullExpYear = expYear + 2000;

    // Create a date object for the expiration date (last day of the month)
    const lastDayOfMonth = new Date(fullExpYear, expMonth, 0);

    // Check if the current date is past the expiration date
    return currentDate > lastDayOfMonth;
}
