import { useEffect } from 'react';
import { Col, Row, Tabs } from 'antd';
import { CoreBackButton, CoreTitle, CoreButton } from '@packages';
import './EntityDetails.scss';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { getEntity } from '@store/slices/organizationSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { EntityDetail, EntityDevices } from '../../../index';

const EntityDetails = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { organizationId = '', entityId = '' } = useParams();
    const { entity } = useSelector((state: RootState) => state.organization);
    const goToEntityUpdate = (e: any, entityId: string) => {
        e.stopPropagation();
        navigate(`/organizations/entity/update/${entityId}`);
    };

    useEffect(() => {
        if (entityId) {
            dispatch(getEntity(entityId));
        }
    }, []);

    const tabItems = [
        {
            label: t('DETAILS'),
            key: '1',
            children: <EntityDetail entity={entity} />,
        },
        {
            label: t('DEVICE'),
            key: '3',
            children: <EntityDevices />,
        },
    ];

    return (
        <div className='container entity-details'>
            <Row gutter={16}>
                <Col span={2}>
                    <CoreBackButton
                        text={''}
                        onClick={() => {
                            navigate(`/organizations/${organizationId}`, {
                                state: {
                                    currentTab: 2,
                                    delay: true,
                                },
                            });
                        }}
                    />
                </Col>
                <Col span={22}>
                    <Row justify='space-between'>
                        <Col>
                            <CoreTitle tag='h1' text={entity?.LegalRegistrationName} />
                            <div>
                                {t('CREATED_DATE')}: {UtcToLocalDate(entity?.CreatedAt)} |{' '}
                                {t('LAST_UPDATED_DATE')}: {UtcToLocalDate(entity?.CreatedAt)}
                            </div>
                        </Col>
                        <Col>
                            <CoreButton
                                type='primary'
                                text={t('EDIT_ENTITY')}
                                icon={<EditOutlined />}
                                onClick={(e) => goToEntityUpdate(e, entity?.EntityId || '')}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div className='left-tab-wrapper'>
                <Tabs tabPosition='left' type='card' items={tabItems} />
            </div>
        </div>
    );
};

export default EntityDetails;
