import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { isNil, omitBy } from 'lodash';
import { ISubscribePlanDetails, ISubscribePlan } from '@models/subscription.model';
import { SubscribeAPI } from '@service/subscription.service';
import { calculateTimeDifference } from '@utils/calculateDayDifference';
type PricePlansLabels = {
    [key: string]: string;
};

interface ISubscribeState {
    status: RequestStatus;
    data: ISubscribePlan[];
    detailsData: ISubscribePlanDetails;
    tiers: any;
}

const initialState: ISubscribeState = {
    status: 'idle',
    data: [],
    detailsData: {} as ISubscribePlanDetails,
    tiers: [],
};

export const getSubscription = createAsyncThunk('getSubscription', async () => {
    try {
        const response = await SubscribeAPI.getSubscription();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getTiers = createAsyncThunk('getTiers', async () => {
    try {
        const response = await SubscribeAPI.getTiers();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const Subscribe = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSubscription.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getSubscription.fulfilled, (state, action) => {
                const response = omitBy(action.payload, isNil);
                state.status = 'idle';
                state.detailsData = action.payload;
            })
            .addCase(getTiers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTiers.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getTiers.fulfilled, (state, action) => {
                const response = omitBy(action.payload, isNil);
                state.status = 'idle';
                state.data = action.payload.Tiers;
            });
    },
});

export const subscribe = (state: RootState) => state.subscribe;
export const tierData = (state: RootState) => state.subscribe.data;

export const tierSelector = createSelector([tierData], (data) => {
    return data?.map((item) => ({
        value: item.ItemId,
        label: item.Name,
    }));
});

export const pricePlanLabelsSelector = createSelector([tierData], (data) => {
    if (!data || data.length === 0) return {};
    const keys = Object.keys(data[0]).filter((key) => key !== 'ItemId' && key !== 'Name');

    const customLabelMapping = (label: string): string => {
        return label
            .replace('Number Of Users', 'Users')
            .replace('Number Of Invoices', 'Invoices')
            .replace('Number Of Entities', 'Entities')
            .replace('Number Of Devices', 'Devices')
            .replace('Number Of Organizations', 'Organizations');
    };

    const plansLabel: PricePlansLabels = keys.reduce((labels, key) => {
        labels[key] = customLabelMapping(
            key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
        );
        return labels;
    }, {} as PricePlansLabels);

    return plansLabel;
});

export const plansTableDataSelector = createSelector(
    [tierData, pricePlanLabelsSelector],
    (data: ISubscribePlan[], pricePlanLabels) => {
        if (!data || data.length === 0) return [];

        return Object.keys(pricePlanLabels).map((feature, index) => {
            const row: any = {
                key: (index + 1).toString(),
                Features: pricePlanLabels[feature],
            };

            data.forEach((tier) => {
                const columnKey = tier.Name.toLowerCase().replace(/\s/g, '_');
                row[columnKey] = tier[feature as keyof ISubscribePlan] ?? '---';
            });

            return row;
        });
    },
);

export const selectCurrentSubscriptions = (state: RootState) => state.subscribe?.detailsData;

export const selectIsTrialSubscriptions = createSelector(
    [selectCurrentSubscriptions],
    (subscription) => {
        return subscription?.IsTrial;
    },
);

export const selectSubscriptionsExpireTime = createSelector(
    [selectCurrentSubscriptions],
    (subscription) => {
        const expireDate = subscription.Expiration;
        return calculateTimeDifference(expireDate);
    },
);

export default Subscribe.reducer;
