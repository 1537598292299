import { useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import { CoreBackButton, CoreTitle, CoreStatus, CoreButton } from '@packages';
import './OrganizationDetails.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { getOrganization } from '@store/slices/organizationSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
import { OrganizationDetail, OrganizationDevice, OrganizationEntity } from '../../index';

type tabsType = 1 | 2 | 3;

const OrganizationDetails = () => {
    const { t } = useTranslation();
    const { organization } = useSelector((state: RootState) => state.organization);
    const { organizationId = '' } = useParams();
    const dispatch = useAppDispatch();

    const { state }: { state?: { currentTab: tabsType } } = useLocation();
    const [activeTabsKey, setActiveTabsKey] = useState<tabsType>(state?.currentTab || 1);

    useEffect(() => {
        dispatch(getOrganization(organizationId));
    }, [organizationId]);

    const tabItems = [
        {
            label: t('DETAILS'),
            key: '1',
            children: <OrganizationDetail />,
        },
        {
            label: t('ENTITY'),
            key: '2',
            children: <OrganizationEntity />,
        },
        {
            label: t('DEVICE'),
            key: '3',
            children: <OrganizationDevice />,
        },
    ];

    const tabItems2 = [
        {
            label: t('DETAILS'),
            key: '1',
            children: <OrganizationDetail />,
        },
        {
            label: t('ENTITY'),
            key: '2',
            children: <OrganizationEntity />,
        },
    ];
    const navigate = useNavigate();
    const [invoicePhase] = useState(organization?.InvoicePhase);

    const goToOrganizationStepper = (e: any) => {
        e.stopPropagation();
        navigate(`/organization-setup/${organizationId}/invoice-phase?phase=${invoicePhase}`);
    };

    return (
        <div className='container organization-details'>
            <Row justify='space-between'>
                <Col span={18}>
                    <div className='organization-name-wrapper'>
                        <CoreBackButton
                            text={''}
                            onClick={() => {
                                navigate('/organizations');
                            }}
                        />
                        <CoreTitle tag='h2' text={organization?.LegalRegistrationName || ''} />
                        {organization?.InvoicePhase === 1 ? (
                            <div
                                className='upgrade-phase'
                                onClick={(e) => goToOrganizationStepper(e)}
                                aria-hidden='true'
                            >
                                Upgrade to Phase 2
                            </div>
                        ) : null}
                    </div>
                    <div>
                        {t('PHASE')}:{' '}
                        <CoreStatus
                            status={organization?.InvoicePhase === 1 ? '1' : '2'}
                            type='basic'
                        />{' '}
                        | {t('VAT_REGISTRATION_NUMBER')}:{' '}
                        <CoreStatus
                            status={organization?.VatRegistrationNumber || ''}
                            type='basic'
                        />{' '}
                        | {t('CREATED_DATE')}: {UtcToLocalDate(organization?.CreatedAt)} |{' '}
                        {t('LAST_UPDATED_DATE')}: {UtcToLocalDate(organization?.UpdatedAt)}
                    </div>
                </Col>
                <Col span={6}>
                    <div className='edit-button'>
                        <CoreButton
                            text={t('EDIT_ORGANIZATION')}
                            type='primary'
                            linkTo={`/organizations/update/${organizationId}`}
                        />
                    </div>
                </Col>
            </Row>

            <div className='left-tab-wrapper'>
                <Tabs
                    activeKey={activeTabsKey.toString()}
                    onChange={(value) => {
                        setActiveTabsKey(Number(value) as tabsType);
                    }}
                    tabPosition='left'
                    type='card'
                    items={organization?.InvoicePhase === 1 ? tabItems2 : tabItems}
                />
            </div>
        </div>
    );
};

export default OrganizationDetails;
