import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { IPaymentMethods } from '@models/payment-method';
import { AxiosError } from 'axios';

export const PaymentAPI = {
    addPayment: async (paymentId: string): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `PaymentCommand/AddPaymentMethod`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {
                paymentMethodId: paymentId,
            });
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getPaymentMethods: async (): Promise<IPaymentMethods> => {
        const url = APP_CONFIG.eisUrl + `PaymentQuery/GetPaymentMethods`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result?.Cards || [];
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },
};
