import { CoreButton, CoreTitle } from '@packages';
import { Row, Col, Table, Spin, Popover, Button, Select } from 'antd';
import { SimpleBarChart, PieChartWithPaddingAngle, TinyLineChart } from '../dashboard/charts';
import './Dashboard.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { selectLoggedUserInfo, userSelectRole } from '@store/slices/userSlice';
import {
    getInvoiceTypeCounts,
    getMonthlyStats,
    getTopCustomers,
    getTotalCounts,
    InvoiceTypeCounts,
    TopCustomers,
    TotalCounts,
    MonthlyStats,
} from '@store/slices/dashboardSlice';
import DashboardAccessibility from '../dashboard/dashboard-accessibility/DashboardAccessibility';
import { DownloadOutlined, FilterOutlined, SyncOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_YEAR, getYearOptions } from '@utils/dashboardUtils';
// import { getSubscribePlanDetails } from "@store/slices/subscribeSlice";
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { UtcToLocalDate } from '@utils/date';

const Dashboard = () => {
    const { t } = useTranslation();
    const { FirstName, LastName, LogInCount } = useAppSelector(selectLoggedUserInfo).userDetails;
    const { isLoading: TotalCountsLoading, data: TotalCountsData } = useAppSelector(TotalCounts);
    const { isLoading: InvoiceTypeCountsLoading, data: InvoiceTypeCountsData } =
        useAppSelector(InvoiceTypeCounts);
    const { isLoading: TopCustomersLoading, data: TopCustomersData } = useAppSelector(TopCustomers);
    const { isLoading: MonthlyStatsLoading, data: MonthlyStatsData } = useAppSelector(MonthlyStats);
    const { isAdmin } = useAppSelector(userSelectRole);
    const { CreatedAt } = useSelector((state: RootState) => state.subscribe.detailsData);
    const navigate = useNavigate();

    const columns = [
        {
            title: t('NAME'),
            dataIndex: 'CustomerName',
            key: 'CustomerName',
        },
        {
            title: t('TOTAL_SALES'),
            dataIndex: 'TotalAmount',
            key: 'TotalAmount',
            render: (text: string) => <span>SAR {text}</span>,
        },
        {
            title: t('TOTAL_TAX'),
            dataIndex: 'TotalTax',
            key: 'TotalTax',
            render: (text: string) => <span>SAR {text}</span>,
        },
    ];

    const dispatch = useAppDispatch();
    const [year, setYear] = useState<string>(DEFAULT_YEAR);

    const getDashboardData = () => {
        dispatch(getInvoiceTypeCounts());
        dispatch(getTopCustomers());
        dispatch(getTotalCounts());
        dispatch(getMonthlyStats(year));
    };

    useEffect(() => {
        // dispatch(getSubscribePlanDetails());
        getDashboardData();
    }, []);

    const [openFilter, setOpenFilter] = useState<boolean>(false);

    if (isAdmin) {
        navigate('/users');
        return <></>;
    }

    const handleMonthChange = (value: string) => {
        setYear(value);
        dispatch(getMonthlyStats(value));
    };

    return (
        <div className='dashboard-wrapper'>
            <div className='dashboard-inner-wrapper'>
                <div className='top-area'>
                    {LogInCount === 1 ? (
                        <div className='dashboard-title-area mb-20'>
                            <CoreTitle tag='h1' text={t('WELCOME_DASHBOARD')} />
                        </div>
                    ) : (
                        <div className='dashboard-title-area'>
                            <CoreTitle tag='h1' text={t('WELCOME_BACK')} />
                            <p>
                                {t('GOOD_TO_SEE_YOU_AGAIN')} {FirstName} {LastName}
                            </p>
                        </div>
                    )}
                </div>

                <div className='dashboard-accessibility-actions'>
                    <Button icon={<SyncOutlined />} onClick={getDashboardData} />
                    <Button icon={<DownloadOutlined />} />

                    <Popover
                        placement='bottomRight'
                        showArrow={false}
                        content={<DashboardAccessibility />}
                        open={openFilter}
                        onOpenChange={(value) => setOpenFilter(value)}
                        trigger='click'
                    >
                        <CoreButton type='basic' icon={<FilterOutlined />} />
                    </Popover>
                </div>
            </div>

            <div className='dashboard-content'>
                <Row gutter={16}>
                    <Col span={12}>
                        <div className='single-card'>
                            <div className='single-card-title-area'>
                                <CoreTitle
                                    className='single-card-title'
                                    tag='h6'
                                    text={t('TOTAL_NUMBER_OF_INVOICE')}
                                />
                            </div>
                            <Row>
                                <Col span={16}>
                                    <CoreTitle
                                        className='user-count'
                                        tag='h2'
                                        text={TotalCountsData?.AllCount?.toString() || '0'}
                                    />
                                </Col>
                                <Col span={8}>
                                    <TinyLineChart></TinyLineChart>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='single-card'>
                            <div className='single-card-title-area'>
                                <CoreTitle
                                    className='single-card-title'
                                    tag='h6'
                                    text={t('TOTAL_INVOICE_SUBMITTED')}
                                />
                            </div>
                            <Row>
                                <Col span={16}>
                                    <CoreTitle
                                        className='user-count'
                                        tag='h2'
                                        text={
                                            TotalCountsData?.TotalSubmittedToZATCACount?.toString() ||
                                            '0'
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <TinyLineChart></TinyLineChart>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div className='single-card'>
                            <div className='single-card-title-area'>
                                <CoreTitle
                                    className='single-card-title'
                                    tag='h6'
                                    text={t('TOTAL_INVOICE_REJECTED')}
                                />
                            </div>
                            <Row>
                                <Col span={16}>
                                    <CoreTitle
                                        className='user-count'
                                        tag='h2'
                                        text={TotalCountsData?.RejectedCount?.toString() || '0'}
                                    />
                                </Col>
                                <Col span={8}>
                                    <TinyLineChart></TinyLineChart>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='single-card'>
                            <div className='single-card-title-area'>
                                <CoreTitle
                                    className='single-card-title'
                                    tag='h6'
                                    text={t('TOTAL_INVOICE_WARNED')}
                                />
                            </div>
                            <Row>
                                <Col span={16}>
                                    <CoreTitle
                                        className='user-count'
                                        tag='h2'
                                        text={
                                            TotalCountsData?.ClearedWithWarningCount?.toString() ||
                                            '0'
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <TinyLineChart></TinyLineChart>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className='chart-card-area'>
                    <div className='card-title-area'>
                        <CoreTitle tag='h5' text={t('VALUE_OF_INVOICE')} />
                        <Select
                            size='small'
                            bordered={false}
                            onChange={handleMonthChange}
                            className='month-selection'
                            defaultValue={DEFAULT_YEAR}
                            value={year}
                            options={getYearOptions(UtcToLocalDate(CreatedAt?.toString()))}
                        />
                    </div>
                    <div className='statistics'>
                        <div className='generated'>
                            <p>{t('TOTAL_SALES')}</p>
                            <div className='count'>
                                <span>SAR</span>
                                <CoreTitle
                                    tag='h2'
                                    text={MonthlyStatsData?.YearlyTotalSales?.toString()}
                                />
                            </div>
                        </div>
                        <div className='generated'>
                            <p>{t('TOTAL_TAXABLE_AMOUNT')}</p>
                            <div className='count'>
                                <span>SAR</span>
                                <CoreTitle
                                    tag='h2'
                                    text={MonthlyStatsData?.YearlyTotalTaxableAmount?.toString()}
                                />
                            </div>
                        </div>
                    </div>
                    <SimpleBarChart data={MonthlyStatsData?.MonthlyStats || []} />
                </div>
                <Row gutter={16}>
                    <Col md={24} xl={10}>
                        <div className='chart-card-area'>
                            <div className='card-title-area'>
                                <CoreTitle tag='h5' text={t('TYPES_OF_GENERATED_INVOICE')} />
                            </div>
                            {InvoiceTypeCountsLoading ? (
                                <div className='loader'>
                                    <Spin size='large' spinning={true} delay={500} />
                                </div>
                            ) : (
                                <PieChartWithPaddingAngle data={InvoiceTypeCountsData} />
                            )}
                        </div>
                    </Col>
                    <Col md={24} xl={14}>
                        <div className='chart-card-area'>
                            <div className='card-title-area'>
                                <CoreTitle tag='h5' text={t('TOP_CUSTOMERS')} />
                            </div>
                            <Table
                                className='dashboard-customer-table'
                                loading={TopCustomersLoading}
                                dataSource={TopCustomersData}
                                columns={columns}
                                pagination={false}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Dashboard;
